import React, { useEffect } from 'react';
import { TextareaAutosize } from '@mui/material';
import { Flashcard, FlashcardContent } from '../../../types/Flashcard';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { reportContent } from '../../../services/study';
import { Vignette, VignetteContent } from '../../../types/Vignette';
import { useSnackbar } from 'notistack';
import { hapticsImpactLight } from '../../../utils/haptics';
import { PanelType } from '../../../types/Study';

interface ReportCardProps {
  flashcard?: Flashcard | undefined;
  flashcardVariant?: FlashcardContent | undefined;
  vignette?: Vignette | undefined;
  vignetteVariant?: VignetteContent | undefined;
  setPanelType: (panelType: PanelType) => void;
  setReportIsOpen?: (isOpen: boolean) => void;
  reportValue?: string;
  setReportValue: (value: string) => void;
}

const ReportCard: React.FC<ReportCardProps> = ({
  flashcard,
  flashcardVariant,
  vignette,
  vignetteVariant,
  setPanelType,
  setReportIsOpen,
  reportValue,
  setReportValue,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const currentUser = useSelector((state: RootState) => state.auth.userInfo);
  const textareaRef = React.createRef<HTMLTextAreaElement>();

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.setSelectionRange(
        textareaRef.current.value.length,
        textareaRef.current.value.length
      );
    }
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setReportValue(e.target.value);
  };

  const handleReportSubmit = async (
    e?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (e) {
      e.preventDefault();
    }
    if (!currentUser?.email) return;
    let content = '',
      id = '';
    if (flashcard && flashcardVariant) {
      id = flashcard.id;
      content = flashcardVariant.back;
    }
    if (vignette && vignetteVariant) {
      id = vignette.id;
      content = vignetteVariant.question + '\n\nAnswer Options:';
      vignetteVariant.answerOptions.forEach((answerOption, i) => {
        content += `\n\n${String.fromCharCode(65 + i) + '. ' + answerOption.optionText + (answerOption.optionLetter === vignetteVariant.correctAnswer ? ' (Correct)' : '')}`;
      });
      content += '\n\nExplanation:\n' + vignetteVariant.explanation;
    }

    try {
      const reportInfo = {
        email: currentUser.email,
        contentId: id,
        contentText: content,
        reason: reportValue || 'No reason provided',
      };
      await reportContent(reportInfo);
      enqueueSnackbar('Thank you! A physician will review this shortly.', {
        autoHideDuration: 3000,
      });
      setPanelType(PanelType.explanation);
      if (setReportIsOpen) {
        setReportIsOpen(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleKeyUp = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Escape') {
      setPanelType(PanelType.explanation);
      if (setReportIsOpen) {
        setReportIsOpen(false);
      }
    }
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleReportSubmit();
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
    }
  };

  return (
    <div className="report-card">
      <div className="report-card_header">
        <div style={{ display: 'flex', alignItems: 'center', gap: '.75rem' }}>
          <h3>Report Question</h3>
        </div>
      </div>
      <div className="report-card_body">
        <form>
          <TextareaAutosize
            ref={textareaRef}
            style={{
              width: '100%',
              fontSize: '16px',
            }}
            aria-label="Report Card"
            value={reportValue}
            onChange={handleChange}
            onKeyUp={handleKeyUp}
            onKeyDown={handleKeyDown}
            placeholder="Please write your feedback here..."
          />
          <button
            type="submit"
            className="button button--submit"
            onClick={(e) => {
              handleReportSubmit(e);
              hapticsImpactLight();
            }}
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default ReportCard;
