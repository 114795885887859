import { hapticsImpactLight } from '../../../utils/haptics';
import {
  createFlashcardVariant,
  updateFlashcardBE,
  updateFlashcardVariantBE,
} from '../../../services/flashcards';
import { useEffect, useState } from 'react';
import { Flashcard, FlashcardContent } from '../../../types/Flashcard';
import { enqueueSnackbar } from 'notistack';
import { useHotkeys } from 'react-hotkeys-hook';
import { UserExam } from '../../../types/User';
import CustomModal from '../../Global/CustomModal';

interface EditFlashcardProps {
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
  flashcardId: string;
  activeFlashcard: Flashcard;
  setKeybindsDisabled?: (isDisabled: boolean) => void;
  availableExams: UserExam[] | [];
  refreshActiveFlashcard: (flashcardId: string) => void;
}

const EditFlashcard: React.FC<EditFlashcardProps> = ({
  isEditing,
  setIsEditing,
  flashcardId,
  activeFlashcard,
  setKeybindsDisabled,
  availableExams,
  refreshActiveFlashcard,
}) => {
  const [currentFlashcardMergedContent, setCurrentFlashcardMergedContent] =
    useState<string>('');
  const [currentFlashcardExplanation, setCurrentFlashcardExplanation] =
    useState<string>('');
  const [currentFlashcardYield, setCurrentFlashcardYield] = useState<number>();
  const [
    currentFlashcardFirstSessionOrder,
    setCurrentFlashcardFirstSessionOrder,
  ] = useState<number | null>();
  const [selectedExams, setSelectedExams] = useState<UserExam[]>(
    activeFlashcard.exams || []
  );

  const [activeVariantIndex, setActiveVariantIndex] = useState(0);
  const [activeFlashcardVariant, setActiveFlashcardVariant] =
    useState<FlashcardContent>(activeFlashcard.contents[0]);

  const [isCreatingNewVariant, setIsCreatingNewVariant] =
    useState<boolean>(false);

  useHotkeys('ctrl+1', () => keybinds('ctrl+1'), {
    keyup: true,
    enableOnFormTags: true,
  });
  useHotkeys('ctrl+2', () => keybinds('ctrl+2'), {
    keyup: true,
    enableOnFormTags: true,
  });

  const keybinds = (key: string) => {
    if (key === 'ctrl+1') {
      handleClozeShortcut();
    } else if (key === 'ctrl+2') {
      handleHintShortcut();
    }
  };

  useEffect(() => {
    if (
      isEditing &&
      activeFlashcard &&
      activeFlashcardVariant &&
      !isCreatingNewVariant
    ) {
      // Flashcard Variant
      setCurrentFlashcardExplanation(activeFlashcardVariant.explanation);
      const mergedContent = generateMergedContent(
        activeFlashcardVariant.front,
        activeFlashcardVariant.back
      );
      setCurrentFlashcardMergedContent(mergedContent);
      // Flashcard Parent
      setCurrentFlashcardYield(activeFlashcard.yield);
      setCurrentFlashcardFirstSessionOrder(activeFlashcard.firstSessionOrd);
    }
  }, [
    isEditing,
    activeFlashcard,
    activeFlashcardVariant,
    isCreatingNewVariant,
  ]);

  useEffect(() => {
    const orderedVariants = activeFlashcard.contents.sort(
      (a, b) => (a.versionNum || 0) - (b.versionNum || 0)
    );
    setActiveFlashcardVariant(orderedVariants[activeVariantIndex]);
  }, [activeFlashcard, activeVariantIndex]);

  function generateMergedContent(frontHtml: string, backHtml: string) {
    // Parse the front and back HTML strings into DOM
    const parser = new DOMParser();
    const frontDoc = parser.parseFromString(frontHtml, 'text/html');
    const backDoc = parser.parseFromString(backHtml, 'text/html');

    // Find all spans with class 'cloze' in front and back
    const frontClozes = frontDoc.querySelectorAll('span.cloze');
    const backClozes = backDoc.querySelectorAll('span.cloze');

    // Check that the number of clozes match
    if (frontClozes.length !== backClozes.length) {
      throw new Error(
        'Number of cloze deletions in front and back do not match.'
      );
    }

    // Loop through each pair of spans
    frontClozes.forEach((frontSpan, index) => {
      const backSpan = backClozes[index];

      // Extract hint from front
      const frontContent = frontSpan.textContent?.trim();
      let hint = '';

      if (frontContent === '[...]') {
        hint = '';
      } else if (frontContent?.startsWith('[') && frontContent?.endsWith(']')) {
        hint = frontContent?.slice(1, -1);
      }

      // Extract answer from back
      const answer = backSpan.textContent?.trim();

      // Create cloze text
      let clozeText = '';
      if (hint) {
        clozeText = `{{${answer}::${hint}}}`;
      } else {
        clozeText = `{{${answer}}}`;
      }

      // Replace the front span with cloze text
      const clozeTextNode = frontDoc.createTextNode(clozeText);
      frontSpan.parentNode?.replaceChild(clozeTextNode, frontSpan);
    });

    // Return the modified front HTML as text
    return frontDoc.body.textContent || frontDoc.body.innerText;
  }

  function splitCloze(mergedClozeString: string) {
    let front = '';
    let back = '';
    let lastIndex = 0;

    const regex = /\{\{(.*?)(?:::(.*?))?\}\}/g;
    let match;

    while ((match = regex.exec(mergedClozeString)) !== null) {
      const [, answer, hint] = match;

      // Text before the match
      const textBefore = mergedClozeString.slice(lastIndex, match.index);
      front += textBefore;
      back += textBefore;

      // Front replacement
      let frontReplacement;
      if (hint !== undefined) {
        frontReplacement = `<span class='cloze'>[${hint}]</span>`;
      } else {
        frontReplacement = `<span class='cloze'>[...]</span>`;
      }

      // Back replacement
      const backReplacement = `<span class='cloze'>${answer}</span>`;

      // Append replacements
      front += frontReplacement;
      back += backReplacement;

      lastIndex = regex.lastIndex;
    }

    // Append any remaining text
    const remainingText = mergedClozeString.slice(lastIndex);
    front += remainingText;
    back += remainingText;

    return {
      front: front,
      back: back,
    };
  }

  const handleExplanationTextareaChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setCurrentFlashcardExplanation(event.target.value);
  };
  const handleYieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentFlashcardYield(Number(event.target.value));
  };
  const handleFirstSessionOrderChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCurrentFlashcardFirstSessionOrder(Number(event.target.value));
  };

  const handleClozeShortcut = () => {
    const textarea = document.activeElement as HTMLTextAreaElement;
    if (!textarea) return;

    const selectionStart = textarea.selectionStart;
    const selectionEnd = textarea.selectionEnd;
    const selectedText = textarea.value.substring(selectionStart, selectionEnd);

    // wrap selected text in double curly braces
    const newText =
      textarea.value.substring(0, selectionStart) +
      `{{${selectedText}}}` +
      textarea.value.substring(selectionEnd);

    textarea.value = newText;
    textarea.selectionStart = selectionStart + 2;
    textarea.selectionEnd = selectionEnd + 2;
    textarea.focus();
    setCurrentFlashcardMergedContent(newText);
  };

  const handleHintShortcut = () => {
    const textarea = document.activeElement as HTMLTextAreaElement;
    if (!textarea) return;

    // Get the current value of the textarea
    const text = textarea.value;

    // Get the current cursor position or selection
    const startPos = textarea.selectionStart;
    const endPos = textarea.selectionEnd;

    // Find the position of the nearest '{{' before the cursor
    const openBracePos = text.lastIndexOf('{{', startPos);

    // Find the position of the nearest '}}' after the cursor
    const closeBracePos = text.indexOf('}}', endPos);

    // Check if both '{{' and '}}' exist and the cursor is between them
    if (
      openBracePos !== -1 &&
      closeBracePos !== -1 &&
      openBracePos < startPos &&
      closeBracePos >= endPos
    ) {
      // Prepare the new text with '::' inserted before '}}'
      const beforeInsertion = text.substring(0, closeBracePos);
      const afterInsertion = text.substring(closeBracePos);

      const newText = beforeInsertion + '::' + afterInsertion;

      // Update the textarea's value
      textarea.value = newText;

      // Set the cursor position after the inserted '::'
      textarea.selectionStart = closeBracePos + 2;
      textarea.selectionEnd = closeBracePos + 2;

      setCurrentFlashcardMergedContent(newText);
    }
  };

  const handleClearClozes = () => {
    const text = currentFlashcardMergedContent;
    if (!text) return;

    // Use regex to replace all clozes and hints
    const regex = /{{(.*?)(?:::.*)?}}/g;
    const newText = text.replace(regex, '$1');

    setCurrentFlashcardMergedContent(newText);
  };

  const updateFlashcard = async () => {
    if (!activeFlashcard) return;

    const updatedFlashcardData = {
      flashcardYield: currentFlashcardYield || null,
      firstSessionOrd: currentFlashcardFirstSessionOrder || null,
      exams: selectedExams.map((exam) => exam.id),
    };

    await updateFlashcardBE(activeFlashcard.id, updatedFlashcardData);
  };

  const updateFlashcardVariant = async () => {
    if (!activeFlashcardVariant) return;

    const { front, back } = splitCloze(currentFlashcardMergedContent);

    const updatedVariant = {
      front: front,
      back: back,
      explanation: currentFlashcardExplanation,
    };
    await updateFlashcardVariantBE(
      // @ts-expect-error - activeFlashcardVariant is not null
      activeFlashcardVariant.id,
      updatedVariant
    );
  };

  const handleSaveNewVariant = async () => {
    const { front, back } = splitCloze(currentFlashcardMergedContent);
    const newVariantData = {
      front: front,
      back: back,
      explanation: currentFlashcardExplanation,
      versionNum: activeFlashcard.contents.length,
    };
    const response = await createFlashcardVariant(flashcardId, newVariantData);
    if (response) {
      await refreshActiveFlashcard(flashcardId);
    }
    setIsCreatingNewVariant(false);
  };

  const submitUpdateFlashcard = async () => {
    try {
      await Promise.all([updateFlashcard(), updateFlashcardVariant()]);
      enqueueSnackbar('Flashcard updated successfully.', {
        autoHideDuration: 3000,
      });
      setIsEditing(false);
      if (setKeybindsDisabled) {
        setKeybindsDisabled(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <CustomModal
      open={isEditing}
      onClose={() => {
        setIsEditing(false);
        if (setKeybindsDisabled) {
          setKeybindsDisabled(false);
        }
      }}
      name={'edit-flashcard'}
    >
      {!isCreatingNewVariant && (
        <div className="modal_header">
          <h2>Edit Flashcard</h2>
          <div className="variant-selector">
            <div>Variants</div>
            {activeFlashcard.contents.map((_variant, index) => (
              <button
                key={index}
                onClick={() => setActiveVariantIndex(index)}
                style={{
                  fontWeight: activeVariantIndex === index ? 'bold' : 'normal',
                }}
              >
                {index + 1}
              </button>
            ))}
          </div>
          <button onClick={() => setIsCreatingNewVariant(true)}>
            Add Variant
          </button>
        </div>
      )}
      {isCreatingNewVariant && (
        <div className="modal_header">
          <h2>Create New Variant</h2>
        </div>
      )}
      <div className="modal_content">
        <form>
          <div className="editor">
            <label htmlFor="editor--flashcard-merged" className="m-t-0">
              Merged
            </label>
            <textarea
              id="editor--flashcard-merged"
              value={currentFlashcardMergedContent}
              onChange={(e) => setCurrentFlashcardMergedContent(e.target.value)}
            ></textarea>
          </div>
          <div className="row">
            <button type="button" onClick={handleClearClozes}>
              Clear Clozes
            </button>
          </div>
          <div className="editor">
            <label htmlFor="editor--flashcard-explanation">Explanation</label>
            <textarea
              id="editor--flashcard-explanation"
              value={currentFlashcardExplanation}
              onChange={handleExplanationTextareaChange}
            ></textarea>
          </div>
          {!isCreatingNewVariant && (
            <div className="row">
              <div className="editor">
                <label htmlFor="editor--flashcard-yield">Yield</label>
                {currentFlashcardYield !== null && (
                  <input
                    id="editor--flashcard-yield"
                    type="number"
                    value={currentFlashcardYield || 0}
                    onChange={handleYieldChange}
                  />
                )}
              </div>
              <div className="editor">
                <label htmlFor="editor--flashcard-first-session-order">
                  First Session Order{' '}
                  {currentFlashcardFirstSessionOrder === null ? '(null)' : ''}
                </label>
                {currentFlashcardFirstSessionOrder !== null && (
                  <input
                    id="editor--flashcard-first-session-order"
                    type="number"
                    value={currentFlashcardFirstSessionOrder}
                    onChange={handleFirstSessionOrderChange}
                  />
                )}
                {currentFlashcardFirstSessionOrder === null && (
                  <button
                    type="button"
                    onClick={() => setCurrentFlashcardFirstSessionOrder(0)}
                  >
                    Set Value
                  </button>
                )}
                {currentFlashcardFirstSessionOrder !== null && (
                  <button
                    type="button"
                    onClick={() => setCurrentFlashcardFirstSessionOrder(null)}
                  >
                    Set Null
                  </button>
                )}
              </div>
            </div>
          )}
          {!isCreatingNewVariant && (
            <div className="editor">
              <label htmlFor="editor--flashcard-exams">Exams</label>
              <select
                id="editor--flashcard-exams"
                multiple
                value={selectedExams.map((exam) => exam.id)}
                onChange={(e) => {
                  const selectedIds = Array.from(
                    e.target.selectedOptions,
                    (option) => option.value
                  );
                  const selectedExams = availableExams.filter((exam) =>
                    selectedIds.includes(exam.id)
                  );
                  setSelectedExams(selectedExams);
                }}
                style={{ height: '13rem' }}
              >
                {availableExams.map((exam) => (
                  <option key={exam.id} value={exam.id}>
                    {exam.name}
                  </option>
                ))}
              </select>
            </div>
          )}
        </form>
      </div>
      {isCreatingNewVariant && (
        <div className="modal_actions">
          <button
            className="button button--secondary button--back"
            onClick={() => {
              setIsCreatingNewVariant(false);
              hapticsImpactLight();
            }}
          >
            Cancel
          </button>
          <span>
            <button
              type="submit"
              className="button button--submit"
              onClick={() => {
                handleSaveNewVariant();
                hapticsImpactLight();
              }}
            >
              Save
            </button>
          </span>
        </div>
      )}
      {!isCreatingNewVariant && (
        <div className="modal_actions">
          <button
            className="button button--secondary button--back"
            onClick={() => {
              setIsEditing(false);
              if (setKeybindsDisabled) {
                setKeybindsDisabled(false);
              }
              hapticsImpactLight();
            }}
          >
            Cancel
          </button>
          <span>
            <button
              type="submit"
              className="button button--submit"
              onClick={() => {
                hapticsImpactLight();
                submitUpdateFlashcard();
              }}
            >
              Submit
            </button>
          </span>
        </div>
      )}
    </CustomModal>
  );
};

export default EditFlashcard;
