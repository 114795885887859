import { Block, StudySession } from '../types/Study';
import { secondsToDisplayTime } from './secondsToDisplayTime';

const SECONDS_PER_FLASHCARD = 15;
const SECONDS_PER_VIGNETTE = 90;
const SECONDS_PER_VIGNETTE_REVIEW = 90;

export const calculateStudyTimeRemaining = (
  todaySession: StudySession,
  blocks: Block[]
) => {
  // Flashcards
  const totalFlashcards = todaySession.flashcardCount || 0;
  const remainingCards = Math.max(
    0,
    totalFlashcards - todaySession.completedFlashcardCount
  );

  // Vignettes
  const totalVignetteCount = todaySession.vignetteCount || 0;
  const completedVignettes = blocks.reduce(
    (count, block) =>
      count +
      (block.isSubmitted
        ? block.vignetteCount
        : Object.keys(block.answers).length),
    0
  );
  const remainingVignettes = Math.max(
    0,
    totalVignetteCount - completedVignettes
  );

  const totalSeconds =
    remainingCards * SECONDS_PER_FLASHCARD +
    remainingVignettes * SECONDS_PER_VIGNETTE +
    remainingVignettes * SECONDS_PER_VIGNETTE_REVIEW;
  return secondsToDisplayTime(totalSeconds);
};

export const calculateStudyProgress = (
  todaySession: StudySession,
  blocks: Block[]
) => {
  // Flashcards
  const totalFlashcards = todaySession.flashcardCount || 0;
  const completedFlashcardCount = todaySession.completedFlashcardCount;

  // Vignettes
  const totalVignetteCount = todaySession.vignetteCount || 0;
  let completedVignettes = 0;
  for (let i = 0; i < blocks.length; i++) {
    if (blocks[i].isSubmitted) {
      completedVignettes += blocks[i].vignetteCount;
    } else {
      completedVignettes += Object.keys(blocks[i].answers).length;
    }
  }

  const numeratorSeconds =
    completedFlashcardCount * SECONDS_PER_FLASHCARD +
    completedVignettes * SECONDS_PER_VIGNETTE;
  const denominatorSeconds =
    totalFlashcards * SECONDS_PER_FLASHCARD +
    totalVignetteCount * SECONDS_PER_VIGNETTE;

  return Math.round((numeratorSeconds / denominatorSeconds) * 100);
};

export const calculateFlashcardProgress = (todaySession: StudySession) => {
  // Flashcards
  const totalFlashcards = todaySession.flashcardCount || 0;
  const completedFlashcardCount = todaySession.completedFlashcardCount;

  return Math.round((completedFlashcardCount / totalFlashcards) * 100);
};
