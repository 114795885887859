import axiosInstance from './axiosCreator';
import { cacheVignetteImages } from '../utils/imgCaching';

export const initDemoUser = async (demoUserId: string) => {
  try {
    const response = await axiosInstance.get(`/demo/init/${demoUserId}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const fetchAnonAuthToken = async () => {
  try {
    const response = await axiosInstance.get('/demo/auth');
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const fetchDemoStudySession = async (demoUserId: string) => {
  try {
    const response = await axiosInstance.get(`/demo/session/${demoUserId}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const fetchDemoBlocksByStudySessionId = async (sessionId: string) => {
  try {
    const response = await axiosInstance.get(
      `/demo/session/${sessionId}/blocks`
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const fetchDemoVignettes = async () => {
  try {
    const response = await axiosInstance.get('/demo/vignettes');
    if (response.data && response.data.length > 0) {
      console.log('Caching vignette images');
      cacheVignetteImages(response.data);
    }
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const transferDemoProgress = async (
  demoUserId: string,
  completedDemoTour: boolean
) => {
  try {
    const response = await axiosInstance.post('/demo/transferProgress', {
      demoUserId,
      completedDemoTour,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
