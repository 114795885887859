import React, { useEffect, useState } from 'react';
import { hapticsImpactLight } from '../../../utils/haptics';
import { Book, Flag, Layers } from 'react-feather';
import { PanelType, StudyPhase } from '../../../types/Study';
import { Intercom } from '../../../assets/svgs/Intercom';
import ReportCard from '../ReportCard';
import { Vignette, VignetteContent } from '../../../types/Vignette';
import { Flashcard, FlashcardContent } from '../../../types/Flashcard';
import { RootState } from '../../../store/store';
import { useSelector } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';
import LinkedFlashcards from '../Exam/LinkedFlashcards';
import { Lightbulb } from '../../../assets/svgs/Lightbulb';
import useWindowWidth from '../../../hooks/useWindowWidth';
import KeybindTooltip from '../../Misc/KeybindTooltip';
import ArticlePage from '../../Library/ArticlePage';
import { Bookmark } from '../../../types/Bookmark';
import { getBookmarks, removeBookmark } from '../../../services/bookmarks';
import { useSnackbar } from 'notistack';

interface StudyPanelProps {
  panelType: PanelType;
  setPanelType: (panelType: PanelType) => void;
  phase: StudyPhase;
  targetArticleId?: string;
  activeVignette?: Vignette | undefined;
  activeVignetteVariant?: VignetteContent | undefined;
  flashcard?: Flashcard | undefined;
  flashcardVariant?: FlashcardContent | undefined;
  linkedFlashcards?: Flashcard[];
  getLinkedFlashcards?: (vignetteId: string) => void;
  setReportIsOpen?: (isOpen: boolean) => void;
  children: React.ReactNode;
  isDemo?: boolean;
  reportValue?: string;
  setReportValue: (value: string) => void;
}

const StudyPanel: React.FC<StudyPanelProps> = ({
  panelType,
  setPanelType,
  phase,
  targetArticleId,
  activeVignette,
  activeVignetteVariant,
  flashcard,
  flashcardVariant,
  linkedFlashcards,
  getLinkedFlashcards,
  setReportIsOpen,
  children,
  isDemo,
  reportValue,
  setReportValue,
}) => {
  const currentUser = useSelector((state: RootState) => state.auth.userInfo);
  const currentUserExams =
    useSelector((state: RootState) => state.auth.userInfo?.exams) || [];
  const primaryExam = currentUserExams.find((exam) => exam.primary);

  const [activeArticleId, setActiveArticleId] = useState<string | null>(null);
  const [userBookmarks, setUserBookmarks] = useState<Bookmark[]>([]);

  const windowWidth = useWindowWidth();
  const { enqueueSnackbar } = useSnackbar();

  // On Mount
  useEffect(() => {
    loadBookmarks();
  }, []);

  // Set the Default Panel to Explanation
  useEffect(() => {
    setPanelType(PanelType.explanation);
    const handleLinkClick = (e: MouseEvent) => {
      const target = e.target as HTMLAnchorElement;
      if (target.tagName === 'A' && target.href.includes('/library/')) {
        const urlParts = target.href.split('/library/');
        if (urlParts.length === 2) {
          const articleId = urlParts[1];
          e.preventDefault();
          loadArticleById(articleId);
        }
      }
    };
    document.addEventListener('click', handleLinkClick, true);
    return () => {
      document.removeEventListener('click', handleLinkClick, true);
    };
  }, [windowWidth]);

  useEffect(() => {
    if (targetArticleId) {
      setActiveArticleId(targetArticleId);
    }
  }, [targetArticleId]);

  // Load Bookmarks for a User
  const loadBookmarks = async () => {
    if (currentUser) {
      const response = await getBookmarks(currentUser.id);
      if (response) {
        setUserBookmarks(response);
      }
    }
  };

  // Remove a Bookmark
  const handleBookmarkDelete = async (articleId: string) => {
    if (currentUser) {
      const response = await removeBookmark(currentUser?.id, articleId);
      if (response) {
        setUserBookmarks(response);
      }
    }
    enqueueSnackbar('Bookmark removed.', {
      autoHideDuration: 3000,
    });
  };

  const loadArticleById = async (id: string | null) => {
    setPanelType(PanelType.library);
    setActiveArticleId(id);
    scrollToLibraryTop();
  };

  const scrollToLibraryTop = () => {
    if (document.querySelector('.phase--flashcard')) {
      const flashcardContainer = document.querySelector(
        '.flashcard_main_card_container'
      ) as HTMLElement;
      if (!flashcardContainer) return;
      const studyPanel = document.querySelector(
        'div.study-panel'
      ) as HTMLElement;
      if (!studyPanel) return;
      const parentRect = flashcardContainer.getBoundingClientRect();
      const childRect = studyPanel.getBoundingClientRect();
      const offset = childRect.top - parentRect.top;
      const scrollTop = flashcardContainer.scrollTop + offset;
      flashcardContainer.scrollTo({
        top: scrollTop,
        behavior: 'smooth',
      });
    } else {
      setTimeout(() => {
        const rightDiv = document.getElementById('right-panel') as HTMLElement;
        if (!rightDiv) return;
        if (windowWidth >= 900) {
          rightDiv.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
        } else {
          const panelGroup = document.querySelector(
            '.panel-group'
          ) as HTMLElement;
          if (!panelGroup) return;
          const parentRect = panelGroup.getBoundingClientRect();
          const childRect = rightDiv.getBoundingClientRect();
          const offset = childRect.top - parentRect.top;
          const scrollTop = panelGroup.scrollTop + offset;
          panelGroup.scrollTo({
            top: scrollTop,
            behavior: 'smooth',
          });
        }
      }, 50);
    }
  };

  return (
    <div className="study-panel">
      <div className="study-panel_header">
        <div className="study-panel_header_top">
          <Tooltip
            title={
              windowWidth > 900 ? (
                <KeybindTooltip name={'Explanation'} keyLetter={'E'} />
              ) : (
                ''
              )
            }
            enterTouchDelay={0}
          >
            <button
              className={`button--explanation ${panelType === PanelType.explanation ? 'button' : 'button--secondary'}`}
              onClick={() => {
                hapticsImpactLight();
                setPanelType(PanelType.explanation);
              }}
            >
              <Lightbulb />
              <span>Explanation</span>
            </button>
          </Tooltip>
          <Tooltip
            title={
              windowWidth > 900 ? (
                <KeybindTooltip name={'Library'} keyLetter={'L'} />
              ) : (
                ''
              )
            }
            enterTouchDelay={0}
          >
            <button
              className={`button--library ${panelType === PanelType.library ? 'button' : 'button--secondary'}`}
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                hapticsImpactLight();
                event.stopPropagation();
                if (
                  phase === StudyPhase.flashcard ||
                  phase === StudyPhase.qbank
                ) {
                  if (targetArticleId) {
                    setPanelType(PanelType.library);
                    setActiveArticleId(targetArticleId);
                  }
                }
              }}
            >
              <Book />
              <span>Library</span>
            </button>
          </Tooltip>
          {phase === StudyPhase.qbank &&
            (primaryExam?.isUsingFlashcards || isDemo) && (
              <Tooltip
                title={
                  windowWidth > 900 ? (
                    <KeybindTooltip name={'Flashcards'} keyLetter={'F'} />
                  ) : (
                    ''
                  )
                }
                enterTouchDelay={0}
              >
                <button
                  className={`button--flashcards ${panelType === PanelType.flashcards ? 'button' : 'button--secondary'}`}
                  onClick={() => {
                    hapticsImpactLight();
                    setPanelType(PanelType.flashcards);
                  }}
                >
                  <Layers />
                  <span>Flashcards</span>
                  {linkedFlashcards && (
                    <span className="badge">{linkedFlashcards.length}</span>
                  )}
                </button>
              </Tooltip>
            )}
        </div>
        <div className="study-panel_header_bottom">
          <Tooltip
            title={
              windowWidth > 900 ? (
                <KeybindTooltip name={'Report'} keyLetter={'R'} />
              ) : (
                ''
              )
            }
            enterTouchDelay={0}
          >
            <button
              className="button button--report"
              onClick={() => {
                hapticsImpactLight();
                setPanelType(PanelType.report);
              }}
            >
              <Flag />
              <span>Report</span>
            </button>
          </Tooltip>
          <Tooltip
            title={windowWidth > 900 ? <KeybindTooltip name={'Help'} /> : ''}
            enterTouchDelay={0}
          >
            <button
              className="button button--intercom"
              onClick={() => {
                window.Intercom('showNewMessage');
                hapticsImpactLight();
              }}
            >
              <Intercom />
              <span>Help</span>
            </button>
          </Tooltip>
        </div>
      </div>
      <div className="study-panel_body">
        {panelType === PanelType.explanation && <>{children}</>}
        {panelType === PanelType.library && (
          <>
            {activeArticleId && (
              <ArticlePage
                articleId={activeArticleId}
                isStudyPanel={true}
                bookmarks={userBookmarks}
                setBookmarks={setUserBookmarks}
                removeBookmark={handleBookmarkDelete}
              />
            )}
          </>
        )}
        {panelType === PanelType.flashcards &&
          (primaryExam?.isUsingFlashcards || isDemo) &&
          linkedFlashcards &&
          activeVignette &&
          getLinkedFlashcards && (
            <LinkedFlashcards
              linkedFlashcards={linkedFlashcards}
              activeVignetteId={activeVignette.id}
              loadingLinkedFlashcards={false}
              getLinkedFlashcards={getLinkedFlashcards}
              isDemo={isDemo}
            />
          )}
        {panelType === PanelType.report && (
          <ReportCard
            vignette={activeVignette}
            vignetteVariant={activeVignetteVariant}
            flashcard={flashcard}
            flashcardVariant={flashcardVariant}
            setPanelType={setPanelType}
            setReportIsOpen={setReportIsOpen}
            reportValue={reportValue}
            setReportValue={setReportValue}
          />
        )}
      </div>
    </div>
  );
};

export default StudyPanel;
