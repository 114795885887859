import { useDispatch } from 'react-redux';
import { updateCurrentUserInfo } from '../services/auth';
import { setUserInfo } from '../slices/auth/authSlice';

export const useUpdateTheme = () => {
  const dispatch = useDispatch();

  const updateTheme = async (darkMode: boolean) => {
    const updateUser = await updateCurrentUserInfo({ darkMode: darkMode });
    if (updateUser) {
      dispatch(setUserInfo(updateUser));
    }
  };

  return { updateTheme };
};
