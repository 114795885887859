import axiosInstance from './axiosCreator';

// Get all bookmarks for a user
export const getBookmarks = async (userId: string) => {
  try {
    const response = await axiosInstance.get(`/bookmarks/${userId}`);
    return response.data;
  } catch (error) {
    console.error('An unexpected error occurred:', error);
    throw error;
  }
};

// Add a bookmark for a user
export const addBookmark = async (userId: string, articleId: string) => {
  try {
    const response = await axiosInstance.post(`/bookmarks/${userId}/add`, {
      articleId,
    });
    return response.data;
  } catch (error) {
    console.error('An unexpected error occurred:', error);
    throw error;
  }
};

// Remove a bookmark for a user
export const removeBookmark = async (userId: string, articleId: string) => {
  try {
    const response = await axiosInstance.post(`/bookmarks/${userId}/remove`, {
      articleId,
    });
    return response.data;
  } catch (error) {
    console.error('An unexpected error occurred:', error);
    throw error;
  }
};
