import { StaticDatePicker } from '@mui/x-date-pickers';
import moment, { Moment } from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { useEffect, useRef, useState } from 'react';
import RegenerateModal from '../../RegenerateModal';
import { useSnackbar } from 'notistack';
import { addOrUpdateUserExam } from '../../../../services/users';
import { setUserInfo } from '../../../../slices/auth/authSlice';
import CustomModal from '../../CustomModal';
import { Edit3 } from 'react-feather';

interface ExamDateSelectProps {
  setIsLoadingStudySessionAndContent: (isLoading: boolean) => void;
  setIsLoadedStudySessionAndContent: (isLoaded: boolean) => void;
  refreshStudySessionAndContent: () => void;
}

const ExamDateSelect: React.FC<ExamDateSelectProps> = ({
  setIsLoadingStudySessionAndContent,
  setIsLoadedStudySessionAndContent,
  refreshStudySessionAndContent,
}) => {
  const currentUser = useSelector((state: RootState) => state.auth.userInfo);
  const todaySession = useSelector((state: RootState) => state.todaySession);
  const currentUserExams =
    useSelector((state: RootState) => state.auth.userInfo?.exams) || [];
  const primaryExam = currentUserExams.find((exam) => exam.primary) || null;
  const primaryExamId = primaryExam?.id || '';

  const [tempExamDate, setTempExamDate] = useState<Moment | null>(
    primaryExam?.examDate ? moment.utc(primaryExam.examDate) : null
  );

  const [examDateSelectionModalIsOpen, setExamDateSelectionModalIsOpen] =
    useState(false);
  const [showRegenModal, setShowRegenModal] = useState(false);
  const [showSaveConfirmation, setShowSaveConfirmation] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const cancelButtonRef = useRef<HTMLButtonElement>(null);
  const submitButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (primaryExam && tempExamDate) {
      const primaryExamDateStr = moment
        .utc(primaryExam.examDate)
        .format('YYYY-MM-DD');
      const tempExamDateStr = tempExamDate.utc().format('YYYY-MM-DD');
      const isSameDate = primaryExamDateStr === tempExamDateStr;
      setUnsavedChanges(!isSameDate);
    } else {
      setUnsavedChanges(false);
    }
  }, [tempExamDate, primaryExam]);

  useEffect(() => {
    if (primaryExam) {
      const examMoment = primaryExam.examDate
        ? moment.utc(primaryExam.examDate)
        : null;
      setTempExamDate(examMoment);
    }
  }, [primaryExam]);

  const saveChangesOrShowConfirmation = async () => {
    if (todaySession.isStarted === false) {
      submitChanges(true);
    } else if (todaySession.isComplete) {
      submitChanges(false);
    } else {
      setShowRegenModal(true);
    }
  };

  const assignExamToUser = async (regenerate: boolean, date: Moment | null) => {
    if (!currentUser || !primaryExam) return;
    setUnsavedChanges(false);

    // Send date as ISO string with time and UTC timezone
    const dateString = date
      ? date.utc().format('YYYY-MM-DDTHH:mm:ss[Z]')
      : null;

    const response = await addOrUpdateUserExam(
      currentUser.id,
      primaryExamId,
      dateString,
      primaryExam.selectedFlashcardCount,
      primaryExam.selectedVignetteCount,
      primaryExam.isUsingFlashcards,
      primaryExam.isUsingQbank,
      primaryExam.hasCompletedRealExam,
      primaryExam.realExamScore,
      false,
      regenerate
    );

    const updatedUser = { ...currentUser };
    updatedUser.exams = response;
    dispatch(setUserInfo(updatedUser));
    if (regenerate) {
      await refreshStudySessionAndContent();
    }
    enqueueSnackbar('Exam updated.', {
      autoHideDuration: 3000,
      style: { width: 'fit-content' },
    });
    if (document.activeElement && document.activeElement !== document.body) {
      (document.activeElement as HTMLElement).blur();
    }
  };

  const submitChanges = async (regenerate: boolean) => {
    setExamDateSelectionModalIsOpen(false);
    setIsLoadingStudySessionAndContent(true);
    setIsLoadedStudySessionAndContent(false);
    if (showSaveConfirmation) {
      setShowSaveConfirmation(false);
      setUnsavedChanges(false);
    }
    await assignExamToUser(regenerate, tempExamDate);
    setIsLoadingStudySessionAndContent(false);
    setIsLoadedStudySessionAndContent(true);
  };

  const handleUnsavedChanges = () => {
    if (cancelButtonRef.current) {
      cancelButtonRef.current.classList.add('shake');
    }
    if (submitButtonRef.current) {
      submitButtonRef.current.classList.add('shake');
    }
    setTimeout(() => {
      if (cancelButtonRef.current) {
        cancelButtonRef.current.classList.remove('shake');
      }
      if (submitButtonRef.current) {
        submitButtonRef.current.classList.remove('shake');
      }
    }, 500);
  };

  return (
    <>
      <button
        className="button button--glass button--exam-date-selection"
        onClick={() => {
          setExamDateSelectionModalIsOpen(true);
        }}
      >
        <div className="button--exam-date-selection_label">
          Exam Date
          <Edit3
            style={{
              width: '.75rem',
              height: '.75rem',
              transform: 'translate(.25rem, 1px)',
            }}
          />
        </div>
        <div className="button--exam-date-selection_value">
          {primaryExam?.examDate
            ? moment.utc(primaryExam.examDate).format('MM/DD/YYYY')
            : 'No Date Selected'}
        </div>
      </button>
      <CustomModal
        open={examDateSelectionModalIsOpen}
        onClose={() => setExamDateSelectionModalIsOpen(false)}
        name={'exam-date-selection'}
        glass={true}
        unsavedChanges={unsavedChanges}
        handleUnsavedChanges={handleUnsavedChanges}
      >
        <div className="modal_header">
          <h2>Exam Date</h2>
        </div>
        <div className="modal_content">
          <StaticDatePicker
            value={tempExamDate ? tempExamDate.utc() : null}
            onChange={(newValue) => {
              if (newValue) {
                setTempExamDate(newValue.utc());
              }
            }}
            minDate={moment.utc().startOf('day')}
            maxDate={moment.utc().add(7, 'years')}
            openTo="month"
            views={['year', 'month', 'day']}
            slotProps={{
              actionBar: { actions: [] },
            }}
          />
        </div>
        <div className="modal_actions">
          <button
            ref={cancelButtonRef}
            className="button button--glass"
            onClick={() => {
              setExamDateSelectionModalIsOpen(false);
              if (primaryExam?.examDate) {
                setTempExamDate(moment.utc(primaryExam.examDate));
              } else {
                setTempExamDate(null);
              }
            }}
          >
            Cancel
          </button>
          <button
            ref={submitButtonRef}
            className="button button--glass"
            onClick={saveChangesOrShowConfirmation}
            disabled={!unsavedChanges}
          >
            Confirm
          </button>
        </div>
      </CustomModal>
      <RegenerateModal
        showRegenModal={showRegenModal}
        setShowRegenModal={setShowRegenModal}
        submitChanges={submitChanges}
        type={'exam-settings'}
      />
    </>
  );
};

export default ExamDateSelect;
