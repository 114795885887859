import React, { useState } from 'react';
import { RootState } from '../../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { addOrUpdateUserExam } from '../../../services/users';
import { setUserInfo } from '../../../slices/auth/authSlice';
import { DatePicker } from '@mui/x-date-pickers';
import { Moment } from 'moment';
import moment from 'moment';
import { hapticsImpactLight } from '../../../utils/haptics';
import useWindowSize from 'react-use/lib/useWindowSize';
import Confetti from 'react-confetti';

interface ExamCompletionProps {
  handleDateChangeOrConfirmation: (
    examDate: Moment | null,
    ignored: boolean
  ) => void;
  closeSecondaryScreen: () => void;
}

const ExamCompletion: React.FC<ExamCompletionProps> = ({
  handleDateChangeOrConfirmation,
  closeSecondaryScreen,
}) => {
  const currentUser = useSelector((state: RootState) => state.auth.userInfo);
  const currentUserExams =
    useSelector((state: RootState) => state.auth.userInfo?.exams) || [];
  const primaryExam = currentUserExams.find((exam) => exam.primary) || null;
  const [examCompleted, setExamCompleted] = useState<boolean | null>(null);
  const [showConfetti, setShowConfetti] = useState(false);

  const [examDate, setExamDate] = useState<Moment | null>(
    moment(primaryExam?.examDate)
  );

  const { width, height } = useWindowSize();

  const dispatch = useDispatch();

  const logExamCompleted = async () => {
    if (!currentUser || !primaryExam) return;
    const response = await addOrUpdateUserExam(
      currentUser.id,
      primaryExam.id,
      examDate ? examDate.toISOString() : null,
      primaryExam.selectedFlashcardCount,
      primaryExam.selectedVignetteCount,
      primaryExam.isUsingFlashcards,
      primaryExam.isUsingQbank,
      true,
      primaryExam.realExamScore || null,
      primaryExam.ignoredExamDate || false,
      false
    );
    const updatedUser = { ...currentUser };
    updatedUser.exams = response;
    dispatch(setUserInfo(updatedUser));
    closeSecondaryScreen();
  };

  return (
    <div className="exam-completion">
      {examCompleted === null && (
        <div className="exam-completion_screen">
          <h1>
            Did you take your {primaryExam?.name}
            {primaryExam?.name === 'USMLE Step 1' ||
            primaryExam?.name === 'USMLE Step 1'
              ? ' exam ?'
              : '?'}
          </h1>
          <div className="button-container">
            <button
              className="button button--glass"
              onClick={() => setExamCompleted(false)}
            >
              Not Yet
            </button>
            <button
              className="button button--glass"
              onClick={() => {
                setShowConfetti(true);
                setExamCompleted(true);
                hapticsImpactLight();
              }}
            >
              Yes
            </button>
          </div>
        </div>
      )}
      {examCompleted === false && (
        <div className="exam-completion_screen">
          <h1>Modify your exam date?</h1>
          <div className="block" style={{ padding: '1.5rem' }}>
            <div className="exam-selector">
              <div className="date-select-wrapper">
                <DatePicker
                  value={examDate}
                  onChange={(newValue) => {
                    setExamDate(newValue);
                  }}
                  minDate={moment().startOf('day')}
                  maxDate={moment().add(7, 'years')}
                  openTo="month"
                  views={['year', 'month', 'day']}
                />
              </div>
              <button
                className="button button--glass"
                onClick={() =>
                  handleDateChangeOrConfirmation(
                    examDate,
                    !!examDate?.isSame(moment(primaryExam?.examDate), 'day')
                  )
                }
              >
                {examDate?.isSame(moment(primaryExam?.examDate), 'day')
                  ? 'Keep Date'
                  : 'Confirm Date'}
              </button>
            </div>
          </div>
        </div>
      )}
      {examCompleted === true && (
        <div className="exam-completion_screen exam-completion_screen--congrats">
          <h1>Congratulations!</h1>
          <p>Ora is happy to be a part of your study journey.</p>
          <button
            className="button button--glass"
            onClick={() => {
              logExamCompleted();
            }}
          >
            Return Home
          </button>
        </div>
      )}
      {showConfetti && (
        <Confetti
          width={width}
          height={height}
          colors={['#FFFFFF', '#FFFFFF90', '#FFFFFF80']}
          recycle={false}
        />
      )}
    </div>
  );
};

export default ExamCompletion;
