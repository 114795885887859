import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { RootState } from '../../store/store';
import { useSelector } from 'react-redux';
import { useHotkeys } from 'react-hotkeys-hook';
import Page from '../../components/Page';
import ArticleBrowser from '../../components/Library/ArticleBrowser';
import ArticlePage from '../../components/Library/ArticlePage';
import { Menu, X } from 'react-feather';
import { hapticsImpactLight } from '../../utils/haptics';
import MSHeader from '../../components/NonAuth/MSHeader';
import MSFooter from '../../components/NonAuth/MSFooter';
import { useUpdateTheme } from '../../hooks/useUpdateTheme';
import { Bookmark } from '../../types/Bookmark';
import { getBookmarks, removeBookmark } from '../../services/bookmarks';
import { useSnackbar } from 'notistack';
import CustomModal from '../../components/Global/CustomModal';
import { createPortal } from 'react-dom'; // Import createPortal

interface LibraryProps {}

const Library: React.FC<LibraryProps> = () => {
  const currentUser = useSelector((state: RootState) => state.auth.userInfo);
  const [activeArticleId, setActiveArticleId] = useState<string | null>(null);
  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);
  const [userBookmarks, setUserBookmarks] = useState<Bookmark[]>([]);
  const [libraryConsentIsOpen, setLibraryConsentIsOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth); // State for window width

  const { articleId } = useParams<{ articleId: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const { updateTheme } = useUpdateTheme();
  const navigate = useNavigate();

  // Hotkey to navigate home when 'esc' is pressed and no article is active
  useHotkeys(
    'esc',
    () => {
      if (!activeArticleId) {
        navigate('/');
      }
    },
    {},
    [activeArticleId]
  );

  useEffect(() => {
    loadBookmarks();
    renderConsentModal();

    const resetLibraryView = () => {
      setActiveArticleId(null);
      const contentWindow = document.querySelector('.page_content');
      if (contentWindow) {
        contentWindow.scrollTo(0, 0);
      }
    };

    const libraryTab = document.getElementById('library-tab');
    if (libraryTab) {
      libraryTab.addEventListener('click', resetLibraryView);
    }

    return () => {
      if (libraryTab) {
        libraryTab.removeEventListener('click', resetLibraryView);
      }
    };
  }, []);

  // Handle window resize to update windowWidth state
  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Load Article by URL change
  useEffect(() => {
    if (articleId) {
      setActiveArticleId(articleId);
      const contentWindow = document.querySelector('.page_content');
      if (contentWindow) {
        contentWindow.scrollTo(0, 0);
      }
      window.scrollTo(0, 0);
    }
  }, [articleId]);

  useHotkeys(
    't',
    () => {
      if (currentUser) {
        updateTheme(!currentUser?.darkMode);
      }
    },
    { keyup: true }
  );

  const loadBookmarks = async () => {
    if (currentUser) {
      const response = await getBookmarks(currentUser.id);
      if (response) {
        setUserBookmarks(response);
      }
    }
  };

  const renderConsentModal = async () => {
    const libraryConsent = localStorage.getItem('library-consent');
    if (!libraryConsent) {
      setTimeout(() => {
        setLibraryConsentIsOpen(true);
      }, 500);
    }
  };

  const handleBookmarkDelete = async (articleId: string) => {
    if (currentUser) {
      const response = await removeBookmark(currentUser?.id, articleId);
      if (response) {
        setUserBookmarks(response);
      }
    }
    enqueueSnackbar('Bookmark removed.', {
      autoHideDuration: 3000,
    });
  };

  // Function to render ArticleBrowser, using a portal for window widths less than 900px
  const renderArticleBrowser = () => {
    const browser = (
      <div
        className={`library_body_browser ${mobileMenuIsOpen ? 'is-open' : ''}`}
      >
        <ArticleBrowser
          activeArticleId={activeArticleId}
          setActiveArticleId={setActiveArticleId}
          setMobileMenuIsOpen={setMobileMenuIsOpen}
          bookmarks={userBookmarks}
          removeBookmark={handleBookmarkDelete}
        />
        <button
          className="button button--close"
          onClick={() => {
            hapticsImpactLight();
            setMobileMenuIsOpen(false);
          }}
        >
          <X />
        </button>
      </div>
    );

    if (windowWidth > 1200) {
      return browser;
    } else {
      if (!activeArticleId) {
        return browser;
      } else {
        const portalRoot = document.body;
        return createPortal(browser, portalRoot);
      }
    }
  };

  const renderLibrary = () => {
    return (
      <div
        className={`library${
          activeArticleId ? ' is-article-page' : ' is-landing-page'
        }${!currentUser ? ' is-non-auth' : ''}`}
      >
        <div className="library_header">
          <div className="library_header_content">
            {activeArticleId && (
              <button
                className="button button--icon-only"
                onClick={() => {
                  setMobileMenuIsOpen(true);
                  hapticsImpactLight();
                }}
              >
                <Menu />
              </button>
            )}
            <h1>Library</h1>
          </div>
        </div>
        <div
          className={`library_body ${
            currentUser && !activeArticleId && userBookmarks.length > 0
              ? 'two-col'
              : ''
          }`}
        >
          {renderArticleBrowser()}
          {activeArticleId && (
            <ArticlePage
              articleId={activeArticleId}
              isStudyPanel={false}
              bookmarks={userBookmarks}
              setBookmarks={setUserBookmarks}
              removeBookmark={handleBookmarkDelete}
            />
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      {!currentUser && (
        <div className="non-auth-library">
          <MSHeader />
          <div
            className={`non-auth-library_body ${
              activeArticleId ? 'is-article-page' : 'is-landing-page'
            }`}
          >
            {renderLibrary()}
          </div>
          <MSFooter />
          <CustomModal
            open={libraryConsentIsOpen}
            onClose={() => setLibraryConsentIsOpen(false)}
            name={'library-consent'}
            glass={true}
            disableBackdropClick={true}
          >
            <div className="modal_content">
              <h2 className="m-t-0">Welcome to the Ora Library</h2>
              <p style={{ fontSize: '1.125rem' }}>
                Over 1700 high-yield articles for Step &amp; Shelf prep.
              </p>
              <button
                className="button button--glass"
                onClick={() => {
                  hapticsImpactLight();
                  localStorage.setItem('library-consent', 'true');
                  setLibraryConsentIsOpen(false);
                }}
                style={{ width: '100%' }}
                tabIndex={1}
              >
                Accept &amp; Explore
              </button>
              <p className="acceptance-text">
                By accessing the Ora library, you agree to the{' '}
                <a href="/terms" tabIndex={0}>
                  Terms
                </a>{' '}
                and{' '}
                <a href="/privacy" tabIndex={0}>
                  Privacy Policy
                </a>
                .
              </p>
            </div>
            <div className="blur-1"></div>
            <div className="blur-2"></div>
          </CustomModal>
        </div>
      )}
      {currentUser && (
        <Page type={activeArticleId ? 'library-landing' : ''}>
          {renderLibrary()}
        </Page>
      )}
    </>
  );
};

export default Library;
