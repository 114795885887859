import CircularProgress from '@mui/material/CircularProgress';

interface CircleProgress {
  value: number;
  percentage?: boolean;
}

const CircleProgress: React.FC<CircleProgress> = ({ value, percentage }) => {
  return (
    <div className="circle-progress">
      <CircularProgress
        variant="determinate"
        value={value}
        sx={{ height: '100% !important', width: '100% !important' }}
      />
      <div className="circle-progress_label">
        {`${Math.round(value)}`}
        {percentage ? '%' : ''}
      </div>
    </div>
  );
};

export default CircleProgress;
