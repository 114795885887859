import { useEffect, useState } from 'react';
import { Check } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import EllipsisLoader from '../../components/Misc/EllipsisLoader';
import { unsubcribeFromEmails } from '../../services/emailPreferences';

interface EmailPreferencesProps {}

const EmailPreferences: React.FC<EmailPreferencesProps> = () => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showError, setShowError] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    handleUnsubscribe();
  }, [navigate]);

  const handleUnsubscribe = async () => {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const userToken = urlParams.get('token');
      if (userToken) {
        const response = await unsubcribeFromEmails(userToken);
        if (response.success) {
          setShowConfirmation(true);
        } else {
          setShowError(true);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="email-preferences">
      <div className="container">
        {showError && (
          <>
            <h1>Error setting email preferences.</h1>
            <p>Please try again later.</p>
            <button
              className="button button--glass m-t-1-50"
              onClick={() => {
                navigate('/');
              }}
            >
              Return to Ora
            </button>
          </>
        )}
        {showConfirmation && !showError && (
          <>
            <Check />
            <h1>You've been unsubscribed.</h1>
            {/* <p>Go to Account to manage your email preferences.</p> */}
            <button
              className="button button--glass m-t-1-50"
              onClick={() => {
                navigate('/');
              }}
            >
              Return to Ora
            </button>
          </>
        )}
        {!showConfirmation && (
          <div>
            <EllipsisLoader />
          </div>
        )}
      </div>
    </div>
  );
};

export default EmailPreferences;
