export const joyrideStyles = {
  options: {
    zIndex: 10000,
    arrowColor: '#20202D',
  },
  tooltip: {
    backgroundColor: '#20202D',
    color: 'var(--type-body-inverse)',
    fontFamily: '"Poppins", "Noto Sans", sans-serif',
    fontWeight: 500,
    borderRadius: '1rem',
    padding: '1.5rem',
    overflow: 'hidden',
  },
  tooltipContent: {
    padding: 0,
    textAlign: 'left',
  },
  tooltipFooter: {
    display: 'none',
  },
  buttonNext: {
    display: 'none',
  },
  buttonBack: {
    display: 'none',
  },
  buttonClose: {
    display: 'none',
  },
};
