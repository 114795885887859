import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StudyPhase, StudySession } from '../../types/Study';

const initialState = {
  id: '',
  date: '',
  flashcardCount: 0,
  completedFlashcardCount: 0,
  vignetteCount: 0,
  blockCount: 0,
  isComplete: false,
  isStarted: false,
  isDiagnostic: false,
  isReview: false,
  isSnoozed: false,
  phase: StudyPhase.flashcard,
  activeBlockId: '',
  vignetteCorrectCount: 0,
  vignetteIncorrectCount: 0,
  vignetteOmittedCount: 0,
  hasSeenPriorities: false,
};

export const todaySessionSlice = createSlice({
  name: 'todaySession',
  initialState,
  reducers: {
    setTodaySession: (_state, action: PayloadAction<StudySession>) => {
      return action.payload;
    },
    clearTodaySession: () => {
      return initialState;
    },
    incrementCompletedFlashcards: (state) => {
      state.completedFlashcardCount += 1;
    },
    decrementCompletedFlashcards: (state) => {
      const newCompletedFlashcardCount = Math.min(
        state.completedFlashcardCount,
        0
      );
      state.completedFlashcardCount = newCompletedFlashcardCount;
    },
  },
});

export const {
  setTodaySession,
  clearTodaySession,
  incrementCompletedFlashcards,
  decrementCompletedFlashcards,
} = todaySessionSlice.actions;

export default todaySessionSlice.reducer;
