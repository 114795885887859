import React, { useEffect, useRef, useState } from 'react';
import Exam from '../../components/Study/Exam';
import { PanelType } from '../../types/Study';
import {
  fetchDemoBlocksByStudySessionId,
  fetchDemoStudySession,
  fetchDemoVignettes,
  initDemoUser,
} from '../../services/demo';
import { setBlocks } from '../../slices/blocks/blocksSlice';
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';
import { setTodaySession } from '../../slices/todaySession/todaySessionSlice';
import { useDispatch, useSelector } from 'react-redux';
import { setVignettes } from '../../slices/vignettes/vignettesSlice';
import { RootState } from '../../store/store';
import {
  fadeInFlashLoader,
  fadeInOutFlashLoader,
  fadeOutFlashLoader,
} from '../../utils/flashLoader';
import {
  updateAuthToken,
  updateRefreshToken,
} from '../../slices/auth/authSlice';
import { useNavigate } from 'react-router-dom';

const Demo: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const vignettes = useSelector(
    (state: RootState) => state.vignettes.vignettes
  );
  const [resourcesLoaded, setResourcesLoaded] = useState(false);
  const [activeBlockId, setActiveBlockId] = useState<string>('');
  const [panelType, setPanelType] = useState<PanelType>(PanelType.library);
  const currentUser = useSelector((state: RootState) => state.auth.userInfo);

  const flashLoaderRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Check if the 'demoUserId' cookie exists
    let demoUserId = Cookies.get('demoUserId');

    document.body.classList.add('study-theme');

    if (!demoUserId) {
      // Generate a new UUID
      demoUserId = uuidv4();

      // Set the 'demoUserId' cookie with an expiration of 2 days
      Cookies.set('demoUserId', demoUserId, { expires: 2 });
    } else {
      // console.log('Existing demoUserId found:', demoUserId);
    }

    // Initialize the demo session
    initDemoSession(demoUserId);
  }, []);

  const initDemoSession = async (demoUserId: string) => {
    if (isLoggedIn()) {
      navigate('/');
      return;
    }

    // Create anon user and issue anon access tokens
    const response = await initDemoUser(demoUserId);
    // Store anon access tokens
    if (response && response.token && response.refreshToken) {
      handleAuthSuccess(response.token, response.refreshToken);
    }

    const studySession = await fetchDemoStudySession(demoUserId);
    if (studySession) {
      dispatch(setTodaySession(studySession));
      setActiveBlockId(studySession.activeBlockId);
      const blocks = await fetchDemoBlocksByStudySessionId(studySession.id);
      if (blocks) {
        dispatch(setBlocks(blocks));
      }
      const vignettes = await fetchDemoVignettes();
      if (vignettes) {
        dispatch(setVignettes(vignettes));
      }
    }
    setResourcesLoaded(true);
  };

  const isLoggedIn = () => {
    return currentUser && !currentUser.isAnon;
  };

  const handleAuthSuccess = (
    authToken: string,
    refreshToken: string | null = null
  ) => {
    dispatch(updateAuthToken(authToken));
    if (refreshToken) dispatch(updateRefreshToken(refreshToken));
  };

  return (
    <>
      <div className={`study demo-xp is-loaded`}>
        <div className="study_main">
          <div className="study_main_body" style={{ overflow: 'hidden' }}>
            {/* {!resourcesLoaded && <div>Loading...</div>} */}
            {resourcesLoaded && (
              <Exam
                vignettes={vignettes}
                panelType={panelType}
                setPanelType={setPanelType}
                isDemo={true}
                activeBlockId={activeBlockId}
                currentBlockNumber={0}
                isDiagnostic={false}
                isStart={false}
                fadeInOutFlashLoader={() =>
                  fadeInOutFlashLoader(flashLoaderRef)
                }
                fadeInFlashLoader={() => fadeInFlashLoader(flashLoaderRef)}
                fadeOutFlashLoader={() => fadeOutFlashLoader(flashLoaderRef)}
                isStartingPhase={false}
                setIsStartingPhase={() => {}}
                // setIsStart={setIsQbankStart}
                // changePhase={changePhase}
                // nextBlock={nextBlock}
                // fadeOutFlashLoader={() => fadeOutFlashLoader(flashLoaderRef)}
                // reviewQbankBlock={reviewQbankBlock}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Demo;
