import React, { useEffect } from 'react';
import type { RootState } from '../store/store';
import { useNavigate } from 'react-router-dom';
// import { refreshToken } from '../services/auth';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getUserInfo } from '../services/auth';
import { setUserInfo, logout } from '../slices/auth/authSlice';
import { CircularProgress } from '@mui/material';

interface ProtectedRouteProps {
  children: React.ReactNode;
}

const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { userInfo } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userInfo = await getUserInfo();
        if (userInfo && !userInfo.isAnon) {
          dispatch(setUserInfo(userInfo));
        } else {
          if (
            window.location.pathname !== '/library' &&
            !window.location.pathname.startsWith('/library') &&
            window.location.pathname !== '/demo' &&
            !window.location.pathname.startsWith('/demo')
          ) {
            dispatch(logout());
            navigate('/auth');
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [dispatch]);

  if (!userInfo) {
    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return <>{children}</>;
};

export default ProtectedRoute;
