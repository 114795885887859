import { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import parse from 'html-react-parser';
import { fetchArticlePreview } from '../../../services/library';
import { Capacitor } from '@capacitor/core';
import useWindowWidth from '../../../hooks/useWindowWidth';

const KeywordPopoversManager = () => {
  const [popoverHref, setPopoverHref] = useState('');
  const [popoverContent, setPopoverContent] = useState('');
  const [anchorRect, setAnchorRect] = useState<DOMRect | null>(null);
  const [mouseX, setMouseX] = useState<number | null>(null);
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const [isPopoverDisabled, setIsPopoverDisabled] = useState(false);
  const popoverRef = useRef<HTMLDivElement>(null);
  const closeTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const hoverTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const windowWidth = useWindowWidth();

  const handleClick = () => {
    setIsPopoverVisible(false);
    setAnchorRect(null);
    setMouseX(null);
    setPopoverContent('');
  };

  // Update the isPopoverDisabled state whenever the windowWidth changes or Capacitor platform status is checked
  useEffect(() => {
    const isNativePlatform = Capacitor.isNativePlatform();
    const isSmallScreen = windowWidth < 900;
    setIsPopoverDisabled(isNativePlatform || isSmallScreen);
  }, [windowWidth]);

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
      // Cleanup timeout on unmount
      if (hoverTimeoutRef.current) {
        clearTimeout(hoverTimeoutRef.current);
      }
    };
  }, []);

  const handlePopoverMouseEnter = () => {
    if (closeTimeoutRef.current) {
      clearTimeout(closeTimeoutRef.current);
      closeTimeoutRef.current = null;
    }
  };

  const handlePopoverMouseLeave = () => {
    closeTimeoutRef.current = setTimeout(() => {
      setIsPopoverVisible(false);
      setAnchorRect(null);
      setMouseX(null);
      setPopoverContent('');
      closeTimeoutRef.current = null;
    }, 200);
  };

  useEffect(() => {
    const handleMouseOver = (event: MouseEvent) => {
      if (isPopoverDisabled) return;
      const target = event.target as HTMLElement;
      if (popoverRef.current && popoverRef.current.contains(target)) {
        return;
      }
      const linkElement = target.closest(
        'a[href*="/library/"]'
      ) as HTMLAnchorElement;
      if (linkElement) {
        const href = linkElement.getAttribute('href');
        const id = href?.split('/library/')[1];
        if (id) {
          if (hoverTimeoutRef.current) {
            clearTimeout(hoverTimeoutRef.current);
          }
          // Start a new hover timeout
          hoverTimeoutRef.current = setTimeout(async () => {
            // Additional check to ensure the mouse is still over the link
            const currentLinkElement = document.querySelector(
              'a[href="' + href + '"]:hover'
            ) as HTMLAnchorElement;
            if (!currentLinkElement) {
              return;
            }
            if (closeTimeoutRef.current) {
              clearTimeout(closeTimeoutRef.current);
              closeTimeoutRef.current = null;
            }
            const data = await fetchArticlePreview(id);
            if (!data || !data.articlePreview) return;
            setPopoverHref(href || '');
            setPopoverContent(data.articlePreview);
            // Capture mouseX for horizontal alignment
            const mouseXPosition = event.clientX;
            setMouseX(mouseXPosition);
            // Use the linkElement's bounding rect for vertical positioning
            const rect = linkElement.getBoundingClientRect();
            setAnchorRect(rect);
            setIsPopoverVisible(true);
          }, 500);
        }
      }
    };

    const handleMouseOut = (event: MouseEvent) => {
      if (isPopoverDisabled) return; // Disable popover if conditions are met
      const target = event.target as HTMLElement;
      const relatedTarget = event.relatedTarget as HTMLElement;
      const linkElement = target.closest(
        'a[href*="/library/"]'
      ) as HTMLAnchorElement;
      const popoverElement = popoverRef.current;
      if (linkElement) {
        if (hoverTimeoutRef.current) {
          clearTimeout(hoverTimeoutRef.current);
          hoverTimeoutRef.current = null;
        }
      }
      if (
        linkElement &&
        (!relatedTarget ||
          (popoverElement && !popoverElement.contains(relatedTarget)))
      ) {
        closeTimeoutRef.current = setTimeout(() => {
          setIsPopoverVisible(false);
          setAnchorRect(null);
          setMouseX(null);
          setPopoverContent('');
          closeTimeoutRef.current = null;
        }, 200);
      }
    };

    document.addEventListener('mouseover', handleMouseOver);
    document.addEventListener('mouseout', handleMouseOut);
    return () => {
      document.removeEventListener('mouseover', handleMouseOver);
      document.removeEventListener('mouseout', handleMouseOut);
    };
  }, [isPopoverDisabled]);

  useEffect(() => {
    if (anchorRect && popoverRef.current && mouseX !== null) {
      const popoverElement = popoverRef.current;
      const scrollX = window.pageXOffset || document.documentElement.scrollLeft;
      const scrollY = window.pageYOffset || document.documentElement.scrollTop;
      const viewportHeight = window.innerHeight;
      const viewportWidth = window.innerWidth;
      const MIN_GAP = 24;
      const GAP = 8; // Gap between anchor and popover
      const MAX_POPOVER_WIDTH = 48 * 16; // 768px
      const MAX_POPOVER_HEIGHT = 32 * 16; // 512px
      // Calculate available width and set maxWidth
      const availableWidth = viewportWidth - 2 * MIN_GAP;
      const maxPopoverWidth = Math.min(MAX_POPOVER_WIDTH, availableWidth);
      popoverElement.style.maxWidth = `${maxPopoverWidth}px`;
      // Temporarily hide the popover to get accurate dimensions
      popoverElement.style.visibility = 'hidden';
      popoverElement.style.display = 'block';
      // Get the dimensions of the popover content
      const popoverRect = popoverElement.getBoundingClientRect();
      // Calculate left position based on mouseX
      let left = mouseX - popoverRect.width / 2;
      // Adjust left to prevent overflow
      if (left < MIN_GAP) {
        left = MIN_GAP;
      } else if (left + popoverRect.width + MIN_GAP > viewportWidth) {
        left = viewportWidth - popoverRect.width - MIN_GAP;
      }
      // Calculate available space below and above the anchor
      const spaceBelow = viewportHeight - anchorRect.bottom - GAP;
      const spaceAbove = anchorRect.top - GAP;
      let top;
      let availableHeight;
      if (spaceBelow >= spaceAbove) {
        // Position below the anchor
        availableHeight = spaceBelow;
        top = anchorRect.bottom + GAP;
      } else {
        // Position above the anchor
        availableHeight = spaceAbove;
        top = anchorRect.top - popoverRect.height - GAP;
      }
      // Limit the popover height to available space and set maxHeight
      const maxPopoverHeight = Math.min(MAX_POPOVER_HEIGHT, availableHeight);
      popoverElement.style.maxHeight = `${maxPopoverHeight}px`;
      popoverElement.style.overflowY = 'auto';
      // Recalculate popoverRect after setting maxHeight
      const finalPopoverRect = popoverElement.getBoundingClientRect();
      // Adjust top position based on where the popover is placed
      if (spaceBelow >= spaceAbove) {
        // Ensure popover doesn't overflow below
        if (top + finalPopoverRect.height > viewportHeight - MIN_GAP) {
          top = viewportHeight - finalPopoverRect.height - MIN_GAP;
        }
      } else {
        // Ensure popover doesn't overflow above
        top = anchorRect.top - finalPopoverRect.height - GAP;
        if (top < MIN_GAP) {
          top = MIN_GAP;
        }
      }
      // Ensure the popover does not overlap the anchor
      if (spaceBelow >= spaceAbove) {
        top = Math.max(top, anchorRect.bottom + GAP);
      } else {
        top = Math.min(top, anchorRect.top - finalPopoverRect.height - GAP);
      }
      // Account for scrolling
      left += scrollX;
      top += scrollY;
      // Set the popover's position
      popoverElement.style.left = `${left}px`;
      popoverElement.style.top = `${top}px`;
      // Make the popover visible
      popoverElement.style.visibility = 'visible';
    }
  }, [anchorRect, mouseX]);

  if (!isPopoverVisible || isPopoverDisabled) return null;
  return ReactDOM.createPortal(
    <div
      className="popover popover--keywords"
      ref={popoverRef}
      onMouseEnter={handlePopoverMouseEnter}
      onMouseLeave={handlePopoverMouseLeave}
    >
      {parse(popoverContent)}
      <div className="popover--keywords_footer">
        <a className="button button--secondary" href={popoverHref}>
          Show Full Article
        </a>
      </div>
    </div>,
    document.body
  );
};

export default KeywordPopoversManager;
