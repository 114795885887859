import axiosInstance from './axiosCreator';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { User, UpdateUserInfoRequest } from '../types/User';

interface UserCredentials {
  email: string;
  password: string;
}

interface RegisterInfo {
  email: string;
  password: string;
  timezone?: string;
}

interface AuthResponse {
  token: string;
  refreshToken: string;
}

interface RegisterResponse {
  success: boolean;
  message: string;
}

// Login function
export const login = async (credentials: UserCredentials) => {
  try {
    const response = await axiosInstance.post<AuthResponse>(
      'auth/login',
      credentials
    );
    return response.data;
  } catch (error) {
    console.error(error);
    // @ts-expect-error TODO: fix
    return error.response.data;
  }
};

// Register function
export const register = async (credentials: RegisterInfo) => {
  try {
    const response = await axiosInstance.post<RegisterResponse>(
      'auth/register',
      credentials
    );
    return response;
  } catch (error) {
    return error;
  }
};

// Refresh auth token
// Use generic axios to avoid intercepting refresh requests in infinite loop
import axios from 'axios';
export const refreshToken = async (currentRefreshToken: string) => {
  try {
    const axiosInstance = axios.create({
      baseURL: import.meta.env.VITE_BACKEND_URL,
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const response = await axiosInstance.post<AuthResponse>('auth/refresh', {
      refreshToken: currentRefreshToken,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// Forgot password
export const forgotPassword = async (email: string) => {
  try {
    const response = await axiosInstance.post('auth/forgotPassword', {
      email: email,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// Reset password
export const resetPassword = async (
  resetToken: string,
  newPassword: string
) => {
  try {
    const response = await axiosInstance.post('auth/resetPassword', {
      resetToken: resetToken,
      newPassword: newPassword,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// Change password
export const changePassword = async (
  token: string,
  oldPassword: string,
  newPassword: string
) => {
  try {
    const response = await axiosInstance.post('auth/changePassword', {
      token: token,
      oldPassword: oldPassword,
      newPassword: newPassword,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getUserInfo = async () => {
  try {
    const response = await axiosInstance.get('/auth/profile');
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const updateCurrentUserInfo = async (
  userInfo: Partial<UpdateUserInfoRequest>
) => {
  try {
    const response = await axiosInstance.post('/auth/updateProfile', userInfo);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// Configure a RTK Query for fetching the user profile
export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_BACKEND_URL,
    prepareHeaders: (headers, { getState }) => {
      // @ts-expect-error TODO: fix
      const token = getState().auth.authToken;
      if (token) {
        // include token in req header
        headers.set('authorization', `Bearer ${token}`);
        return headers;
      }
    },
  }),
  endpoints: (builder) => ({
    getUserInfo: builder.query<User, string>({
      query: () => ({
        url: `/auth/profile`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetUserInfoQuery } = authApi;

export const impersonateUser = async (userId: string) => {
  try {
    const response = await axiosInstance.post('/auth/impersonate', {
      userId,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
