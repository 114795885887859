import React from 'react';
import AppBar from '../Global/AppBar';
import NavBar from '../Global/NavBar';

interface PageProps {
  type?: string;
}

const Page: React.FC<PageProps & { children: React.ReactNode }> = ({
  type,
  children,
}) => {
  return (
    <div className={`page ${type ? 'page--' + type : ''}`}>
      <NavBar />
      <div className="page_content">{children}</div>
      <AppBar />
    </div>
  );
};

export default Page;
