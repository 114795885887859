import { Assent, AssentType } from '../types/Assent';
import axiosInstance from './axiosCreator';

export const VERSIONS = {
  tos: '12-02-2024',
  privacyPolicy: '12-02-2024',
};

export const createAssents = async (
  userId: string,
  assentsData: {
    type: AssentType;
    version: string;
    dateAccepted: string;
  }[]
) => {
  try {
    const response = await axiosInstance.post(`/assent/${userId}`, {
      assents: assentsData,
    });
    return response.data;
  } catch (error) {
    console.error('Error creating assents:', error);
    return null;
  }
};

export const getLatestAssents = async (
  userId: string
): Promise<Assent[] | null> => {
  try {
    const response = await axiosInstance.get(`/assent/${userId}/latest`);
    return response.data;
  } catch (error) {
    console.error('Error fetching latest assents:', error);
    return null;
  }
};

export const getAssentsToAccept = (latestAssents: Assent[]) => {
  // Determine which assents need to be accepted
  const assentsToAccept: { type: AssentType; version: string }[] = [];

  for (const type in VERSIONS) {
    const requiredVersion = VERSIONS[type as AssentType];

    // Skip privacyPolicy 08-07-2024
    if (type === 'privacyPolicy' && requiredVersion === '08-07-2024') {
      continue;
    }

    const userVersion = latestAssents.find(
      (assent) => assent.type === type
    )?.version;

    if (userVersion !== requiredVersion) {
      assentsToAccept.push({
        type: type as AssentType,
        version: requiredVersion,
      });
    }
  }

  return assentsToAccept;
};
