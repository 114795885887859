export const calcDefaultExamDate = (examName: string | undefined) => {
  const today = new Date();
  const examDate = new Date(today);
  switch (examName) {
    case 'USMLE Step 1':
      examDate.setDate(examDate.getDate() + 180); // 6 months
      break;
    case 'USMLE Step 2':
      examDate.setDate(examDate.getDate() + 180); // 6 months
      break;
    case 'Neurology Shelf Exam':
      examDate.setDate(examDate.getDate() + 28); // 4 weeks
      break;
    case 'Psychiatry Shelf Exam':
      examDate.setDate(examDate.getDate() + 28); // 4 weeks
      break;
    case 'Surgery Shelf Exam':
      examDate.setDate(examDate.getDate() + 56); // 8 weeks
      break;
    case 'Family Medicine Shelf Exam':
      examDate.setDate(examDate.getDate() + 28); // 4 weeks
      break;
    case 'OBGYN Shelf Exam':
      examDate.setDate(examDate.getDate() + 42); // 6 weeks
      break;
    case 'Emergency Medicine Shelf Exam':
      examDate.setDate(examDate.getDate() + 28); // 4 weeks
      break;
    case 'Internal Medicine Shelf Exam':
      examDate.setDate(examDate.getDate() + 56); // 8 weeks
      break;
    case 'Pediatrics Shelf Exam':
      examDate.setDate(examDate.getDate() + 42); // 6 weeks
      break;
    default:
      examDate.setDate(examDate.getDate() + 90); // 3 months
      break;
  }
  return examDate.toISOString();
};

export const getDefaultExamDateTime = (examName: string | undefined) => {
  let time = '3 months';
  switch (examName) {
    case 'USMLE Step 1':
      time = '6 months';
      break;
    case 'USMLE Step 2':
      time = '6 months';
      break;
    case 'Neurology Shelf Exam':
      time = '4 weeks';
      break;
    case 'Psychiatry Shelf Exam':
      time = '4 weeks';
      break;
    case 'Surgery Shelf Exam':
      time = '8 weeks';
      break;
    case 'Family Medicine Shelf Exam':
      time = '4 weeks';
      break;
    case 'OBGYN Shelf Exam':
      time = '6 weeks';
      break;
    case 'Emergency Medicine Shelf Exam':
      time = '4 weeks';
      break;
    case 'Internal Medicine Shelf Exam':
      time = '8 weeks';
      break;
    case 'Pediatrics Shelf Exam':
      time = '6 weeks';
      break;
    default:
      time = '3 months';
      break;
  }
  return time;
};
