import { useEffect, useState } from 'react';
import { Flashcard } from '../../../../types/Flashcard';
import { updateLinkedFlashcardAssignments } from '../../../../services/flashcards';
import { enqueueSnackbar } from 'notistack';
import { RootState } from '../../../../store/store';
import { useSelector } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';
import parse from 'html-react-parser';
// import { Copy, Edit3 } from 'react-feather';
import Skeleton from '@mui/material/Skeleton';
import OraSwitch from '../../../CustomMUI/Switch';
import { Clock } from 'react-feather';
// import EditFlashcard from '../../EditFlashcard';

interface LinkedFlashcardsProps {
  linkedFlashcards: Flashcard[];
  activeVignetteId: string;
  loadingLinkedFlashcards: boolean;
  getLinkedFlashcards: (vignetteId: string) => void;
  isDemo?: boolean;
}

const LinkedFlashcards: React.FC<LinkedFlashcardsProps> = ({
  linkedFlashcards,
  activeVignetteId,
  loadingLinkedFlashcards,
  getLinkedFlashcards,
  isDemo = false,
}) => {
  // const currentUser = useSelector((state: RootState) => state.auth.userInfo);
  const primaryExam = useSelector((state: RootState) =>
    state.auth.userInfo?.exams.find((b) => b.primary === true)
  );

  // const [isEditingLinkedFlashcard, setIsEditingLinkedFlashcard] =
  //   useState(false);
  // const [flashcardToEdit, setFlashcardToEdit] = useState<Flashcard | null>(
  //   null
  // );
  const [flashcardPriorities, setFlashcardPriorities] = useState<{
    [key: string]: boolean;
  }>({});

  useEffect(() => {
    if (linkedFlashcards.length) {
      const initialState: { [key: string]: boolean } = {};
      linkedFlashcards.forEach((flashcard) => {
        initialState[flashcard.id] = flashcard.isLinked ?? false;
      });
      setFlashcardPriorities(initialState);
    }
  }, [linkedFlashcards]);

  // useEffect(() => {
  //   if (currentUser?.role === 'admin' && !isEditingLinkedFlashcard) {
  //     refreshLinkedFlashcards();
  //   }
  // }, [isEditingLinkedFlashcard]);

  // const refreshLinkedFlashcards = async () => {
  //   await getLinkedFlashcards(activeVignetteId);
  // };

  const allAssigned = linkedFlashcards
    .filter((flashcard) => flashcard.isNew)
    .every((flashcard) => flashcardPriorities[flashcard.id] === true);

  const handleToggle = (flashcardId: string) => {
    const newPriority = !flashcardPriorities[flashcardId];
    setFlashcardPriorities((prevState) => ({
      ...prevState,
      [flashcardId]: newPriority,
    }));
    togglePriorityCard(flashcardId, newPriority);
  };

  const togglePriorityCard = async (
    flashcardId: string,
    newIsLinkedValue: boolean
  ) => {
    const response = await updateLinkedFlashcardAssignments([
      {
        flashcardId: flashcardId,
        vignetteId: activeVignetteId,
        isLinked: newIsLinkedValue,
      },
    ]);
    if (response && response.success) {
      enqueueSnackbar(
        newIsLinkedValue ? 'Flashcard linked.' : 'Flashcard unlinked.',
        { autoHideDuration: 3000 }
      );
      await getLinkedFlashcards(activeVignetteId);
    }
  };

  const handleToggleAll = async () => {
    const newAssignAll = !allAssigned; // Assign all if not all are assigned, unassign all if all are assigned.
    const updatedFlashcards = linkedFlashcards
      .filter((flashcard) => flashcard.isNew)
      .map((flashcard) => ({
        flashcardId: flashcard.id,
        vignetteId: activeVignetteId,
        isLinked: newAssignAll,
      }));

    const response = await updateLinkedFlashcardAssignments(updatedFlashcards);

    if (response && response.success) {
      const newPriorities: { [key: string]: boolean } = {};
      linkedFlashcards.forEach((flashcard) => {
        newPriorities[flashcard.id] = newAssignAll;
      });

      setFlashcardPriorities(newPriorities);
      enqueueSnackbar(
        newAssignAll ? 'All flashcards linked.' : 'All flashcards unlinked.',
        { autoHideDuration: 3000 }
      );
      await getLinkedFlashcards(activeVignetteId);
    }
  };

  const renderSkeletonCards = () => {
    return [...Array(5)].map((_, index) => (
      <div key={index}>
        <Skeleton
          sx={{
            bgcolor: 'rgba(255,255,255,.05)',
            borderRadius: '1rem',
            margin: '.5rem 0',
          }}
          variant="rectangular"
          animation="wave"
          width={'100%'}
          height={105}
        />
      </div>
    ));
  };

  const renderLinkedFlashcards = () => {
    if (!primaryExam?.isUsingFlashcards && !isDemo) return null;
    if (!linkedFlashcards || !linkedFlashcards.length) return null;
    return (
      <div className="linked-flashcards">
        <div className="linked-flashcards_header">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: '3rem',
              width: '100%',
            }}
          >
            <div>
              <h3>
                <span>Linked Flashcards</span>
              </h3>
              {isDemo ? (
                <p>
                  Linked flashcards of incorrectly answered vignettes are
                  automatically assigned for review.
                </p>
              ) : (
                <p>
                  Linked flashcards of incorrectly answered vignettes are
                  automatically assigned for review. You can make changes here.
                </p>
              )}
            </div>
            {!isDemo && (
              <div>
                <OraSwitch checked={allAssigned} onChange={handleToggleAll} />
              </div>
            )}
          </div>
        </div>
        <div className="linked-flashcards_body">
          {loadingLinkedFlashcards && renderSkeletonCards()}
          {!loadingLinkedFlashcards &&
            linkedFlashcards.map((flashcard, index) => {
              if (!flashcard.contents.length || !flashcard.contents[0].front)
                return null;
              return (
                <>
                  <div
                    className={`linked-card ${
                      flashcardPriorities[flashcard.id] ? 'assigned' : ''
                    }`}
                    key={'flashcard-' + flashcard.id + '-' + index}
                  >
                    <div
                      className={`linked-card_front ${flashcard.contents[0].front.length > flashcard.contents[0].back.length ? 'structural' : ''}`}
                    >
                      {parse(flashcard.contents[0].front)}
                    </div>
                    <div
                      className={`linked-card_back ${flashcard.contents[0].front.length > flashcard.contents[0].back.length ? 'front-based' : ''}`}
                    >
                      {parse(flashcard.contents[0].back)}
                    </div>
                    {/* {currentUser?.role === 'admin' && (
                      <div className="admin-toolbar">
                        <button
                          className="button button--edit-linked-flashcard"
                          onClick={() => {
                            setFlashcardToEdit(flashcard);
                            setIsEditingLinkedFlashcard(true);
                          }}
                        >
                          <Edit3 />
                        </button>
                        <div className="spacer">|</div>
                        <button
                          onClick={() => {
                            navigator.clipboard.writeText(flashcard.id);
                          }}
                          className="admin-id"
                        >
                          <Copy />
                          {flashcard.id}
                        </button>
                        <div className="spacer">|</div>
                        <div className="qa-status">
                          {flashcard.isQAed ? (
                            <div className="is-qaed">Is QAed</div>
                          ) : (
                            <div className="is-not-qaed">Is Not QAed</div>
                          )}
                        </div>
                      </div>
                    )} */}
                    {!isDemo && flashcard.isNew && (
                      <Tooltip
                        title={
                          flashcardPriorities[flashcard.id]
                            ? 'Unassign linked flashcard.'
                            : 'Assign linked flashcard.'
                        }
                        enterTouchDelay={0}
                      >
                        <div className="switch">
                          <OraSwitch
                            checked={!!flashcardPriorities[flashcard.id]}
                            onChange={() => handleToggle(flashcard.id)}
                          />
                        </div>
                      </Tooltip>
                    )}
                    {!isDemo && !flashcard.isNew && (
                      <Tooltip
                        title={'This flashcard is already assigned.'}
                        enterTouchDelay={0}
                      >
                        <div className="due-icon">
                          <Clock />
                        </div>
                      </Tooltip>
                    )}
                  </div>
                </>
              );
            })}
        </div>
        {/* {currentUser?.role === 'admin' && flashcardToEdit && (
          <EditFlashcard
            isEditing={isEditingLinkedFlashcard}
            setIsEditing={setIsEditingLinkedFlashcard}
            flashcardId={flashcardToEdit.id}
            activeFlashcard={flashcardToEdit}
            activeFlashcardVariant={flashcardToEdit.contents[0]}
          />
        )} */}
      </div>
    );
  };

  return renderLinkedFlashcards();
};

export default LinkedFlashcards;
