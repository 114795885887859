import axiosInstance from './axiosCreator';

// Update Topic by ArticleId
export const updateTopic = async (
  articleId: string,
  data: {
    yieldNumber: number;
  }
) => {
  try {
    const response = await axiosInstance.put(`/topics/${articleId}`, data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
