export const flashcardMarks = {
  'USMLE Step 1': {
    ticks: [
      { value: 5400, label: '5,400' },
      { value: 10000, label: '10,000' },
      { value: 15000, label: '15,000' },
      { value: 20000, label: '20,000' },
      { value: 25000, label: '25,000' },
    ],
    step: 500,
  },
  'USMLE Step 2': {
    ticks: [
      { value: 5400, label: '5,400' },
      { value: 10000, label: '10,000' },
      { value: 15000, label: '15,000' },
      { value: 20000, label: '20,000' },
      { value: 25000, label: '25,000' },
    ],
    step: 500,
  },
  'Neurology Shelf Exam': {
    ticks: [
      { value: 600, label: '600' },
      { value: 1200, label: '1,200' },
      { value: 1800, label: '1,800' },
      { value: 2400, label: '2,400' },
      { value: 3000, label: '3,000' },
    ],
    step: 300,
  },
  'Psychiatry Shelf Exam': {
    ticks: [
      { value: 600, label: '600' },
      { value: 1200, label: '1,200' },
      { value: 1800, label: '1,800' },
      { value: 2400, label: '2,400' },
      { value: 3000, label: '3,000' },
    ],
    step: 300,
  },
  'Surgery Shelf Exam': {
    ticks: [
      { value: 800, label: '800' },
      { value: 1600, label: '1,600' },
      { value: 2400, label: '2,400' },
      { value: 3200, label: '3,200' },
      { value: 4000, label: '4,000' },
    ],
    step: 0,
  },
  'Family Medicine Shelf Exam': {
    ticks: [
      { value: 600, label: '600' },
      { value: 1200, label: '1,200' },
      { value: 1800, label: '1,800' },
      { value: 2400, label: '2,400' },
      { value: 3000, label: '3,000' },
    ],
    step: 300,
  },
  'OBGYN Shelf Exam': {
    ticks: [
      { value: 800, label: '800' },
      { value: 1600, label: '1,600' },
      { value: 2400, label: '2,400' },
      { value: 3200, label: '3,200' },
      { value: 4000, label: '4,000' },
    ],
    step: 400,
  },
  'Emergency Medicine Shelf Exam': {
    ticks: [
      { value: 600, label: '600' },
      { value: 1200, label: '1,200' },
      { value: 1800, label: '1,800' },
      { value: 2400, label: '2,400' },
      { value: 3000, label: '3,000' },
    ],
    step: 300,
  },
  'Internal Medicine Shelf Exam': {
    ticks: [
      { value: 1400, label: '1,400' },
      { value: 2800, label: '2,800' },
      { value: 4200, label: '4,200' },
      { value: 5600, label: '5,600' },
      { value: 7000, label: '7,000' },
    ],
    step: 700,
  },
  'Pediatrics Shelf Exam': {
    ticks: [
      { value: 800, label: '800' },
      { value: 1600, label: '1,600' },
      { value: 2400, label: '2,400' },
      { value: 3200, label: '3,200' },
      { value: 4000, label: '4,000' },
    ],
    step: 400,
  },
};

export const qbankMarks = {
  'USMLE Step 1': {
    ticks: [
      { value: 1800, label: '1,800' },
      { value: 2400, label: '2,400' },
      { value: 3600, label: '3,600' },
      { value: 4800, label: '4,800' },
      { value: 6000, label: '6,000' },
    ],
    step: 400,
  },
  'USMLE Step 2': {
    ticks: [
      { value: 1800, label: '1,800' },
      { value: 2400, label: '2,400' },
      { value: 3600, label: '3,600' },
      { value: 4800, label: '4,800' },
      { value: 6000, label: '6,000' },
    ],
    step: 400,
  },
  'Neurology Shelf Exam': {
    ticks: [
      { value: 120, label: '120' },
      { value: 240, label: '240' },
      { value: 360, label: '360' },
      { value: 480, label: '480' },
      { value: 600, label: '600' },
    ],
    step: 60,
  },
  'Psychiatry Shelf Exam': {
    ticks: [
      { value: 120, label: '120' },
      { value: 240, label: '240' },
      { value: 360, label: '360' },
      { value: 480, label: '480' },
      { value: 600, label: '600' },
    ],
    step: 60,
  },
  'Surgery Shelf Exam': {
    ticks: [
      { value: 180, label: '180' },
      { value: 360, label: '360' },
      { value: 540, label: '540' },
      { value: 720, label: '720' },
      { value: 900, label: '900' },
    ],
    step: 90,
  },
  'Family Medicine Shelf Exam': {
    ticks: [
      { value: 120, label: '120' },
      { value: 240, label: '240' },
      { value: 360, label: '360' },
      { value: 480, label: '480' },
      { value: 600, label: '600' },
    ],
    step: 60,
  },
  'OBGYN Shelf Exam': {
    ticks: [
      { value: 180, label: '180' },
      { value: 360, label: '360' },
      { value: 540, label: '540' },
      { value: 720, label: '720' },
      { value: 900, label: '900' },
    ],
    step: 90,
  },
  'Emergency Medicine Shelf Exam': {
    ticks: [
      { value: 120, label: '120' },
      { value: 240, label: '240' },
      { value: 360, label: '360' },
      { value: 480, label: '480' },
      { value: 600, label: '600' },
    ],
    step: 60,
  },
  'Internal Medicine Shelf Exam': {
    ticks: [
      { value: 400, label: '400' },
      { value: 800, label: '800' },
      { value: 1200, label: '1,200' },
      { value: 1600, label: '1,600' },
      { value: 2000, label: '2,000' },
    ],
    step: 200,
  },
  'Pediatrics Shelf Exam': {
    ticks: [
      { value: 180, label: '180' },
      { value: 360, label: '360' },
      { value: 540, label: '540' },
      { value: 720, label: '720' },
      { value: 900, label: '900' },
    ],
    step: 90,
  },
};

export const getSliderValue = (value: number) => {
  return `${value}`;
};

export const defaultFlashcards = (examName: string | undefined) => {
  if (examName) {
    return flashcardMarks[examName as keyof typeof flashcardMarks].ticks[0]
      .value;
  } else {
    return flashcardMarks['USMLE Step 1'].ticks[0].value;
  }
};

export const defaultVignettes = (examName: string | undefined) => {
  if (examName) {
    return qbankMarks[examName as keyof typeof qbankMarks].ticks[0].value;
  } else {
    return qbankMarks['USMLE Step 1'].ticks[0].value;
  }
};

export const setFlashcardMarks = (examName: string | undefined) => {
  if (examName) {
    return flashcardMarks[examName as keyof typeof flashcardMarks].ticks;
  } else {
    return [];
  }
};

export const setFlashcardStep = (examName: string | undefined) => {
  if (examName) {
    return flashcardMarks[examName as keyof typeof flashcardMarks].step;
  } else {
    return 10;
  }
};

export const minFlashcards = (examName: string | undefined) => {
  if (examName) {
    return flashcardMarks[examName as keyof typeof flashcardMarks].ticks[0]
      .value;
  } else {
    return flashcardMarks['USMLE Step 1'].ticks[0].value;
  }
};

export const maxFlashcards = (examName: string | undefined) => {
  if (examName) {
    return flashcardMarks[examName as keyof typeof flashcardMarks].ticks[4]
      .value;
  } else {
    return flashcardMarks['USMLE Step 1'].ticks[4].value;
  }
};

export const setVignetteMarks = (examName: string | undefined) => {
  if (examName) {
    return qbankMarks[examName as keyof typeof qbankMarks].ticks;
  } else {
    return [];
  }
};

export const setVignetteStep = (examName: string | undefined) => {
  if (examName) {
    return qbankMarks[examName as keyof typeof qbankMarks].step;
  } else {
    return 10;
  }
};

export const minVignettes = (examName: string | undefined) => {
  if (examName) {
    return qbankMarks[examName as keyof typeof qbankMarks].ticks[0].value;
  } else {
    return qbankMarks['USMLE Step 1'].ticks[0].value;
  }
};

export const maxVignettes = (examName: string | undefined) => {
  if (examName) {
    return qbankMarks[examName as keyof typeof qbankMarks].ticks[4].value;
  } else {
    return qbankMarks['USMLE Step 1'].ticks[4].value;
  }
};
