import axiosInstance from './axiosCreator';

export const unsubcribeFromEmails = async (userToken: string) => {
  try {
    const response = await axiosInstance.put(
      `/emailPreferences?token=${userToken}`,
      {
        emails: false,
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
