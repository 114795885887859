import { useEffect, useState } from 'react';
import { hapticsImpactLight } from '../../../../utils/haptics';
import { Vignette, VignetteContent } from '../../../../types/Vignette';
import { enqueueSnackbar } from 'notistack';
import {
  updateVignetteBE,
  updateVignetteVariantBE,
} from '../../../../services/vignettes';
import { useDispatch } from 'react-redux';
import {
  replaceVignetteById,
  replaceVignetteVariantById,
} from '../../../../slices/vignettes/vignettesSlice';
import { UserExam } from '../../../../types/User';
import CustomModal from '../../../Global/CustomModal';

interface EditVignetteProps {
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
  activeVignette: Vignette;
  setKeybindsDisabled: (disabled: boolean) => void;
  availableExams: UserExam[] | [];
}

const EditVignette: React.FC<EditVignetteProps> = ({
  isEditing,
  setIsEditing,
  activeVignette,
  setKeybindsDisabled,
  availableExams,
}) => {
  const [activeVignetteVariant, setActiveVignetteVariant] =
    useState<VignetteContent>(activeVignette.contents[0]);
  const [currentVignetteQuestion, setCurrentVignetteQuestion] = useState('');
  const [currentVignetteExplanation, setCurrentVignetteExplanation] =
    useState('');
  const [currentVignetteExhibit, setCurrentVignetteExhibit] = useState('');
  const [
    currentVignetteLearningObjective,
    setCurrentVignetteLearningObjective,
  ] = useState('');
  const [currentAnswerOptions, setCurrentAnswerOptions] = useState(
    activeVignetteVariant?.answerOptions
  );
  const [currentCorrectAnswer, setCurrentCorrectAnswer] = useState(
    activeVignetteVariant?.correctAnswer
  );
  const [currentVignetteYield, setCurrentVignetteYield] = useState<number>();
  const [
    currentVignetteFirstSessionOrder,
    setCurrentVignetteFirstSessionOrder,
  ] = useState<number | null>();
  const [activeVariantIndex, setActiveVariantIndex] = useState(0);
  const [selectedExams, setSelectedExams] = useState<UserExam[]>(
    activeVignette.exams || []
  );

  const [isEditingAnswerOptions, setIsEditingAnswerOptions] = useState(false);
  const [temporaryAnswerOptions, setTemporaryAnswerOptions] = useState(
    activeVignetteVariant?.answerOptions.map((option) => ({
      optionText: option.optionText,
    }))
  );
  const [temporaryCorrectAnswer, setTemporaryCorrectAnswer] = useState(
    activeVignetteVariant?.correctAnswer
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (isEditing && activeVignette && activeVignetteVariant) {
      // Vignette Parent
      setCurrentVignetteYield(activeVignette.yield);
      setCurrentVignetteFirstSessionOrder(activeVignette.firstSessionOrd);
      // Vignette Variant
      setCurrentVignetteQuestion(activeVignetteVariant.question);
      setCurrentVignetteExplanation(activeVignetteVariant.explanation);
      setCurrentVignetteExhibit(activeVignetteVariant?.exhibit || '');
      setCurrentVignetteLearningObjective(
        activeVignetteVariant.learningObjective
      );
      setCurrentAnswerOptions(activeVignetteVariant.answerOptions);
      setCurrentCorrectAnswer(activeVignetteVariant.correctAnswer);
    }
  }, [isEditing, activeVignette, activeVignetteVariant]);

  useEffect(() => {
    setActiveVignetteVariant(activeVignette.contents[activeVariantIndex]);
  }, [activeVignette, activeVariantIndex]);

  const handleQuestionTextareaChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setCurrentVignetteQuestion(event.target.value);
  };

  const handleExplanationTextareaChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setCurrentVignetteExplanation(event.target.value);
  };

  const handleExhibitTextareaChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setCurrentVignetteExhibit(event.target.value);
  };

  const handleLearningObjectiveTextareaChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setCurrentVignetteLearningObjective(event.target.value);
  };
  const handleYieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentVignetteYield(Number(event.target.value));
  };
  const handleFirstSessionOrderChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCurrentVignetteFirstSessionOrder(Number(event.target.value));
  };

  const handleAnswerOptionChange = (index: number, value: string) => {
    setCurrentAnswerOptions((prevOptions) => {
      const updatedOptions = [...prevOptions];
      updatedOptions[index] = {
        ...updatedOptions[index],
        optionText: value,
      };
      return updatedOptions;
    });
  };

  const updateVignette = async () => {
    if (!activeVignette) return;
    if (currentVignetteYield === undefined) return;
    if (currentVignetteFirstSessionOrder === undefined) return;
    const updatedVignetteData = {
      vignetteYield: currentVignetteYield,
      firstSessionOrd: currentVignetteFirstSessionOrder,
      exams: selectedExams.map((exam) => exam.id),
    };
    const updatedVignette = await updateVignetteBE(
      activeVignette.id,
      updatedVignetteData
    );
    if (updatedVignette) {
      dispatch(
        replaceVignetteById({
          vignetteId: activeVignette.id,
          newYieldNumber: updatedVignette.yield,
          newFirstSessionOrd: updatedVignette.firstSessionOrd,
          newExams: selectedExams,
        })
      );
    }
  };

  const updateVignetteVariant = async () => {
    if (!activeVignetteVariant) return;
    const updatedVariant = {
      question: currentVignetteQuestion,
      explanation: currentVignetteExplanation,
      exhibit: currentVignetteExhibit,
      learningObjective: currentVignetteLearningObjective,
      answerOptions: currentAnswerOptions,
      correctAnswer: currentCorrectAnswer,
    };
    const updatedVignette = await updateVignetteVariantBE(
      activeVignetteVariant.id,
      updatedVariant
    );
    if (updatedVignette) {
      dispatch(
        replaceVignetteVariantById({
          vignetteId: activeVignette.id,
          variantId: updatedVignette.id,
          newQuestion: updatedVignette.question,
          newExplanation: updatedVignette.explanation,
          newExhibit: updatedVignette.exhibit,
          newLearningObjective: updatedVignette.learningObjective,
          newAnswerOptions: updatedVignette.answerOptions,
        })
      );
    }
  };

  const handleTemporaryAnswerOptionChange = (
    index: number,
    newText: string
  ) => {
    setTemporaryAnswerOptions((prevOptions) => {
      const newOptions = [...prevOptions];
      newOptions[index] = { ...newOptions[index], optionText: newText };
      return newOptions;
    });
  };

  const handleTemporaryCorrectAnswerChange = (optionLetter: string) => {
    setTemporaryCorrectAnswer(optionLetter);
  };

  const handleRemoveTemporaryAnswerOption = (index: number) => {
    setTemporaryAnswerOptions((prevOptions) => {
      const newOptions = [...prevOptions];
      newOptions.splice(index, 1);
      return newOptions;
    });
    // Reset correct answer if it was removed
    if (String.fromCharCode(65 + index) === temporaryCorrectAnswer) {
      setTemporaryCorrectAnswer('A');
    }
  };

  const handleAddTemporaryAnswerOption = () => {
    setTemporaryAnswerOptions((prevOptions) => [
      ...prevOptions,
      { optionText: '' },
    ]);
  };

  const submitUpdateVignette = async () => {
    try {
      await Promise.all([updateVignette(), updateVignetteVariant()]);
      enqueueSnackbar('Vignette updated successfully.', {
        autoHideDuration: 3000,
      });
      setIsEditing(false);
      if (setKeybindsDisabled) {
        setKeybindsDisabled(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <CustomModal
      open={isEditing}
      onClose={() => {
        setIsEditing(false);
        setKeybindsDisabled(false);
      }}
      name={'edit-vignette'}
    >
      <div className="modal_header">
        <h2>Edit Vignette</h2>
        <div className="variant-selector">
          <div>Variants</div>
          {activeVignette.contents.map((_variant, index) => (
            <button
              key={index}
              onClick={() => setActiveVariantIndex(index)}
              style={{
                fontWeight: activeVariantIndex === index ? 'bold' : 'normal',
              }}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </div>
      <div className="modal_content">
        <form>
          <div className="editor">
            <label htmlFor="editor--vignette-question" className="m-t-0">
              Question
            </label>
            <textarea
              id="editor--vignette-question"
              value={currentVignetteQuestion}
              onChange={handleQuestionTextareaChange}
            ></textarea>
          </div>
          {!isEditingAnswerOptions && (
            <div className="answer-choices">
              <label>Answers</label>
              {currentAnswerOptions.map((answerOption, index) => (
                <div
                  key={index}
                  className={`answer-choice ${answerOption.optionLetter === currentCorrectAnswer ? 'correct' : ''}`}
                >
                  <label htmlFor={`answer-choice-${index}`}>
                    {answerOption.optionLetter}.
                  </label>
                  <input
                    type="text"
                    id={`answer-choice-${index}`}
                    value={answerOption.optionText}
                    onChange={(e) =>
                      handleAnswerOptionChange(index, e.target.value)
                    }
                  />
                </div>
              ))}
              <button
                type="button"
                onClick={() => setIsEditingAnswerOptions(true)}
              >
                Edit
              </button>
            </div>
          )}
          {isEditingAnswerOptions && (
            <div className="answer-choices">
              <label>Editing Answers</label>
              {temporaryAnswerOptions.map((answerOption, index) => {
                const optionLetter = String.fromCharCode(65 + index);
                return (
                  <div
                    key={index}
                    className={`answer-choice ${
                      optionLetter === temporaryCorrectAnswer ? 'correct' : ''
                    }`}
                  >
                    <label htmlFor={`answer-choice-${index}`}>
                      {optionLetter}.
                    </label>
                    <input
                      type="text"
                      id={`answer-choice-${index}`}
                      value={answerOption.optionText}
                      onChange={(e) =>
                        handleTemporaryAnswerOptionChange(index, e.target.value)
                      }
                    />
                    {/* Radio button for selecting the correct answer */}
                    <input
                      type="radio"
                      name="correct-answer"
                      checked={optionLetter === temporaryCorrectAnswer}
                      onChange={() =>
                        handleTemporaryCorrectAnswerChange(optionLetter)
                      }
                    />
                    {/* Remove button */}
                    <button
                      type="button"
                      onClick={() => handleRemoveTemporaryAnswerOption(index)}
                    >
                      Remove
                    </button>
                  </div>
                );
              })}
              {/* Add new answer option */}
              <button type="button" onClick={handleAddTemporaryAnswerOption}>
                Add Answer Option
              </button>
              <div>
                <button
                  type="button"
                  onClick={() => {
                    setTemporaryAnswerOptions(
                      currentAnswerOptions.map((option) => ({
                        optionText: option.optionText,
                      }))
                    );
                    setTemporaryCorrectAnswer(currentCorrectAnswer);
                    setIsEditingAnswerOptions(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setCurrentAnswerOptions(
                      temporaryAnswerOptions.map((option, index) => ({
                        optionLetter: String.fromCharCode(65 + index),
                        optionText: option.optionText,
                        frequencyChosen: 0,
                      }))
                    );
                    setCurrentCorrectAnswer(temporaryCorrectAnswer);
                    setIsEditingAnswerOptions(false);
                  }}
                >
                  Apply
                </button>
              </div>
            </div>
          )}
          <div className="editor">
            <label htmlFor="editor--vignette-explanation">Explanation</label>
            <textarea
              id="editor--vignette-explanation"
              value={currentVignetteExplanation}
              onChange={handleExplanationTextareaChange}
            ></textarea>
          </div>
          <div className="editor m-t-0-75">
            <label htmlFor="editor--vignette-exhibit">Exhibit</label>
            <textarea
              id="editor--vignette-exhibit"
              value={currentVignetteExhibit}
              onChange={handleExhibitTextareaChange}
            ></textarea>
          </div>
          <div className="editor m-t-0-75">
            <label htmlFor="editor--vignette-lo">Learning Objective</label>
            <textarea
              id="editor--vignette-lo"
              value={currentVignetteLearningObjective}
              onChange={handleLearningObjectiveTextareaChange}
            ></textarea>
          </div>
          <div
            className="m-t-0-75"
            style={{
              display: 'flex',
              gap: '1.5rem',
              alignItems: 'flex-start',
              paddingBottom: '1.5rem',
            }}
          >
            <div className="editor">
              <label htmlFor="editor--vignette-yield">Yield</label>
              {currentVignetteYield !== null && (
                <input
                  id="editor--vignette-yield"
                  type="number"
                  value={currentVignetteYield || 0}
                  onChange={handleYieldChange}
                />
              )}
            </div>
            <div className="editor">
              <label htmlFor="editor--vignette-first-session-order">
                First Session Order{' '}
                {currentVignetteFirstSessionOrder === null ? '(null)' : ''}
              </label>
              {currentVignetteFirstSessionOrder !== null && (
                <input
                  id="editor--vignette-first-session-order"
                  type="number"
                  value={currentVignetteFirstSessionOrder}
                  onChange={handleFirstSessionOrderChange}
                />
              )}
              {currentVignetteFirstSessionOrder === null && (
                <button
                  type="button"
                  onClick={() => setCurrentVignetteFirstSessionOrder(0)}
                >
                  Set Value
                </button>
              )}
              {currentVignetteFirstSessionOrder !== null && (
                <button
                  type="button"
                  onClick={() => setCurrentVignetteFirstSessionOrder(null)}
                >
                  Set Null
                </button>
              )}
            </div>
          </div>
          <div
            className="m-t-0-75"
            style={{
              display: 'flex',
              gap: '1.5rem',
              alignItems: 'flex-start',
              paddingBottom: '1.5rem',
            }}
          >
            <div className="editor">
              <label htmlFor="editor--vignette-exam">Exam</label>
              <select
                name="editor--vignette-exam"
                id="editor--vignette-exam"
                multiple
                value={selectedExams.map((exam) => exam.id)}
                onChange={(e) => {
                  const selectedIds = Array.from(
                    e.target.selectedOptions,
                    (option) => option.value
                  );
                  const selectedExamObjects = availableExams.filter((exam) =>
                    selectedIds.includes(exam.id)
                  );
                  setSelectedExams(selectedExamObjects);
                }}
                style={{ height: '13rem' }}
              >
                {availableExams.map((exam) => (
                  <option key={exam.id} value={exam.id}>
                    {exam.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </form>
      </div>
      <div className="modal_actions">
        <button
          className="button button--secondary button--back"
          onClick={() => {
            setIsEditing(false);
            setKeybindsDisabled(false);
            hapticsImpactLight();
          }}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="button button--submit"
          onClick={() => {
            hapticsImpactLight();
            submitUpdateVignette();
          }}
        >
          Submit
        </button>
      </div>
    </CustomModal>
  );
};

export default EditVignette;
