import moment from 'moment';
import CustomModal from '../../../components/Global/CustomModal';
import {
  Assent,
  AssentType,
  AssentTypeLabels,
  AssentTypeLinks,
} from '../../../types/Assent';
import { User } from '../../../types/User';
import { createAssents } from '../../../services/assent';
import { ArrowRight } from 'react-feather';
import { useEffect, useRef } from 'react';

interface AssentsModalProps {
  currentUser: User | null;
  assentsToAccept: {
    type: AssentType;
    version: string;
  }[];
  setAssentsToAccept: (
    assents: { type: AssentType; version: string }[]
  ) => void;
}

const AssentsModal: React.FC<AssentsModalProps> = ({
  currentUser,
  assentsToAccept,
  setAssentsToAccept,
}) => {
  const acceptButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        acceptButtonRef.current &&
        event.target instanceof Node &&
        !acceptButtonRef.current.contains(event.target)
      ) {
        handleOutsideClick();
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const acceptPolicies = async () => {
    if (!currentUser) return;
    const assents: Assent[] = [];
    assentsToAccept.forEach((assent) => {
      assents.push({
        type: assent.type,
        version: assent.version,
        dateAccepted: moment().toISOString(),
        location: 'update',
      });
    });
    await createAssents(currentUser?.id, assents);
  };

  const handleOutsideClick = () => {
    if (acceptButtonRef.current) {
      acceptButtonRef.current.classList.add('shake');
    }
    setTimeout(() => {
      if (acceptButtonRef.current) {
        acceptButtonRef.current.classList.remove('shake');
      }
    }, 500);
  };

  return (
    <>
      {currentUser && assentsToAccept && assentsToAccept.length > 0 && (
        <CustomModal
          name="assents"
          open={true}
          onClose={() => setAssentsToAccept([])}
          glass={true}
          disableBackdropClick={true}
        >
          <div className="modal_header">
            <h2>Policy Updates</h2>
          </div>
          <div className="modal_content">
            {assentsToAccept.length === 1 ? (
              <p className="m-t-0">
                The following policy has changed since you last logged in:
              </p>
            ) : (
              <p className="m-t-0">
                The following policies have changed since you last logged in:
              </p>
            )}
            <div>
              {assentsToAccept.length === 1 && (
                <div className="policy-row">
                  <p>
                    <strong>{AssentTypeLabels[assentsToAccept[0].type]}</strong>
                    <br />
                    {moment(assentsToAccept[0].version, 'MM-DD-YYYY').format(
                      'MMMM D, YYYY'
                    )}
                  </p>
                  <a
                    href={AssentTypeLinks[assentsToAccept[0].type]}
                    target="_blank"
                    rel="noreferrer"
                  >
                    View
                    <ArrowRight />
                  </a>
                </div>
              )}
              {assentsToAccept.length > 1 &&
                assentsToAccept.map((assent, index) => (
                  <div className="policy-row">
                    <p key={index}>
                      <strong>{AssentTypeLabels[assent.type]}</strong>
                      <br />
                      {moment(assent.version, 'MM-DD-YYYY').format(
                        'MMMM D, YYYY'
                      )}
                    </p>
                    <a
                      href={AssentTypeLinks[assentsToAccept[index].type]}
                      target="_blank"
                      rel="noreferrer"
                    >
                      View
                      <ArrowRight />
                    </a>
                  </div>
                ))}
            </div>
          </div>
          <div className="modal_actions">
            <button
              ref={acceptButtonRef}
              className="button button--glass"
              style={{ width: '100%' }}
              onClick={() => {
                acceptPolicies();
                setAssentsToAccept([]);
              }}
            >
              <span>Accept Updates</span>
            </button>
          </div>
          <div className="blur-1"></div>
          <div className="blur-2"></div>
        </CustomModal>
      )}
    </>
  );
};

export default AssentsModal;
