export const SuspendedCards = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 17L10 21M2 12L10 16M15.4221 14.4219L21.0781 20.0779M12 2L2 7L12 12L22 7L12 2ZM22.25 17.25C22.25 19.4591 20.4591 21.25 18.25 21.25C16.0409 21.25 14.25 19.4591 14.25 17.25C14.25 15.0409 16.0409 13.25 18.25 13.25C20.4591 13.25 22.25 15.0409 22.25 17.25Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
