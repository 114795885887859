import React, { useRef, useState, useEffect, useMemo } from 'react';
import { Canvas, useFrame, useThree, extend } from '@react-three/fiber';
import * as THREE from 'three';
import { SphereGeometry } from 'three';
import { Text } from '@react-three/drei';
import { useNavigate } from 'react-router-dom';

extend({ InstancedMesh: THREE.InstancedMesh });

interface Particle {
  x: number;
  y: number;
  z: number;
  speed: number;
  label: { name: string; id: string } | null;
  labelPosition: [number, number, number];
}

interface ParticlesProps {
  count: number;
  radius: number;
  orbit: boolean;
  formCheckmark: boolean;
  labels: { name: string; id: string }[];
}

const Label: React.FC<{
  text: string;
  id: string;
  position: [number, number, number];
}> = ({ text, id, position }) => {
  const navigate = useNavigate();
  return (
    <Text
      position={position}
      fontSize={12}
      color="#ffffff"
      material={
        new THREE.MeshBasicMaterial({
          color: new THREE.Color('#ffffff'),
          transparent: false,
          opacity: 1,
          depthTest: false,
          depthWrite: false,
          toneMapped: false,
        })
      }
      onPointerOver={() => (document.body.style.cursor = 'pointer')}
      onPointerOut={() => (document.body.style.cursor = 'auto')}
      onClick={() => {
        navigate(`/library/${id}`);
        document.body.style.cursor = 'auto';
      }}
    >
      {text}
    </Text>
  );
};

const Particles: React.FC<ParticlesProps> = ({ count, labels }) => {
  const [usedLabels, setUsedLabels] = useState(new Set<string>());
  const meshRef = useRef<THREE.InstancedMesh>(null);
  const tempObject = new THREE.Object3D();
  const screenWidth = window.innerWidth;
  const offset = 50;

  const particlesData = useMemo(() => {
    const data: Particle[] = [];
    for (let i = 0; i < count; i++) {
      const initialX = Math.random() * screenWidth - screenWidth / 2 - offset;
      const initialY = (Math.random() - 0.5) * 100;
      const initialZ = (Math.random() - 0.5) * 100;
      data.push({
        x: initialX,
        y: initialY,
        z: initialZ,
        speed: Math.random() * 1.5 + 1,
        label:
          i % 75 === 0
            ? labels[Math.floor(Math.random() * labels.length)]
            : null,
        labelPosition: [initialX, initialY, initialZ],
      });
    }
    return data;
  }, [count, screenWidth, labels]);

  const [labelPositions, setLabelPositions] = useState(
    particlesData.map((p) => p.labelPosition)
  );

  useFrame(() => {
    if (!meshRef.current) return;

    const newPositions: [number, number, number][] = [];
    const newUsedLabels = new Set(usedLabels);

    for (let i = 0; i < count; i++) {
      const particle = particlesData[i];
      particle.x += particle.speed * 0.02;
      if (particle.x > screenWidth / 2 + offset) {
        particle.x = -screenWidth / 2 - offset;

        if (i % 100 === 0) {
          if (particle.label) {
            newUsedLabels.delete(particle.label.id);
          }

          let newLabel;
          const availableLabels = labels.filter(
            (label) => !newUsedLabels.has(label.id)
          );
          if (availableLabels.length > 0) {
            newLabel =
              availableLabels[
                Math.floor(Math.random() * availableLabels.length)
              ];
            newUsedLabels.add(newLabel.id);
          } else {
            newLabel = null;
          }

          particle.label = newLabel;
        }
      }

      particle.y = particle.y + 0.0125 * Math.sin(particle.x / 100);

      tempObject.position.set(particle.x, particle.y, particle.z);
      tempObject.updateMatrix();
      meshRef.current.setMatrixAt(i, tempObject.matrix);

      newPositions.push([particle.x, particle.y + 10, particle.z] as [
        number,
        number,
        number,
      ]);
    }

    setUsedLabels(newUsedLabels);
    setLabelPositions(newPositions);
    meshRef.current.instanceMatrix.needsUpdate = true;
  });

  return (
    <>
      <instancedMesh ref={meshRef} args={[undefined, undefined, count]}>
        <primitive attach="geometry" object={new SphereGeometry(1, 16, 16)} />
        <meshBasicMaterial attach="material" color={'white'} />
      </instancedMesh>
      {labelPositions.map((position, index) => {
        const particle = particlesData[index];
        return particle.label ? (
          <Label
            key={index}
            text={particle.label.name}
            id={particle.label.id}
            position={position}
          />
        ) : null;
      })}
    </>
  );
};

const CameraController = () => {
  const { camera, gl } = useThree();
  useEffect(() => {
    camera.position.set(0, 0, 500);
    return () => {};
  }, [camera, gl]);
  return null;
};

interface ThreeWaveProps {
  absolutePosition?: boolean;
  showLabels: boolean;
  transitionStarted: boolean;
  topics?: { name: string; id: string }[];
}

const ThreeWave: React.FC<ThreeWaveProps> = React.memo(
  ({ absolutePosition, showLabels, transitionStarted, topics }) => {
    const [showMotion, setShowMotion] = useState(true);
    const [orbit, setOrbit] = useState(true);
    const [formCheckmark] = useState(false);
    const particleCount = 500;
    const particleRadius = 350;
    const [startingOpacity, setStartingOpacity] = useState(0);

    useEffect(() => {
      if (transitionStarted) {
        setOrbit(false);
      }
    }, [transitionStarted]);

    useEffect(() => {
      if (window.matchMedia('(prefers-reduced-motion: reduce)').matches) {
        setShowMotion(false);
      }
      setStartingOpacity(1);
    }, []);

    return (
      <>
        <div
          className="three-orb-container"
          style={{
            height: '100%',
            width: '100%',
            backgroundColor: 'transparent',
            border: 0,
            position: absolutePosition ? 'absolute' : 'fixed',
            top: 0,
            left: 0,
            zIndex: 0,
            opacity: startingOpacity,
            transition: 'opacity 5s 1s ease',
            minHeight: '700px',
          }}
        >
          <Canvas style={{ height: '100%', width: '100%' }}>
            <CameraController />
            <ambientLight />
            {showMotion && (
              <Particles
                count={particleCount}
                radius={particleRadius}
                orbit={orbit}
                formCheckmark={formCheckmark}
                labels={showLabels && topics && topics.length > 0 ? topics : []}
              />
            )}
          </Canvas>
        </div>
      </>
    );
  },
  () => {
    return true;
  }
);

export default ThreeWave;
