import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Global/Header';
import Start from '../../components/Home/Start';
import { HeaderType, SecondaryScreen } from '../../types/Header';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearTodaySession,
  setTodaySession,
} from '../../slices/todaySession/todaySessionSlice';
import { clearBlocks } from '../../slices/blocks/blocksSlice';
import { clearPastFlashcards } from '../../slices/pastFlashcards/pastFlashcardsSlice';
import { ArrowRight, Check, X } from 'react-feather';
import { RootState } from '../../store/store';
import { useHotkeys } from 'react-hotkeys-hook';
import { hapticsImpactLight } from '../../utils/haptics';
import CircularProgress from '@mui/material/CircularProgress';
import { StartType } from '../../types/Study';
import { useRefreshStudySessionAndContent } from '../../utils/refreshStudySession';
import { interpolateGradient } from '../../utils/dynamicBackground';
import { updateCurrentUserInfo } from '../../services/auth';
import { setUserInfo } from '../../slices/auth/authSlice';
import useIdle from '../../utils/useIdle';
import ThreeOrb from '../../components/Home/ThreeOrb';
import { updateTopicTree } from '../../services/topicCustomization';
import { TopicTreeNode } from '../../types/TopicCustomization';
import moment, { Moment } from 'moment';
import { updateStudySession } from '../../services/studySession';
import { AlarmClock } from '../../assets/svgs/AlarmClock';
import { addOrUpdateUserExam } from '../../services/users';
import { enqueueSnackbar } from 'notistack';
import { posthog } from 'posthog-js';
import AppBar from '../../components/Global/AppBar';
import TopicFilters from './TopicFilters';
import ExamCompletion from './ExamCompletion';
import Search from '../../components/Library/Search';
import WelcomeVideo from '../../components/Onboarding/WelcomeVideo';
import { useUpdateTheme } from '../../hooks/useUpdateTheme';
import HomeFeatureTour from '../../components/Home/HomeFeatureTour';
import useWindowWidth from '../../hooks/useWindowWidth';
import { getAssentsToAccept, getLatestAssents } from '../../services/assent';
import { AssentType } from '../../types/Assent';
import AssentsModal from '../../components/Home/AssentsModal';
import PriorityDisplay from '../../components/Home/PriorityDisplay';
import NavBar from '../../components/Global/NavBar';
import AppPromo from '../../assets/images/app-promo.png';
import QRCode from '../../assets/ora-qr.svg';
import { createPortal } from 'react-dom';

interface HomeProps {}

const Home: React.FC<HomeProps> = () => {
  const todaySession = useSelector((state: RootState) => state.todaySession);
  const currentUserExams =
    useSelector((state: RootState) => state.auth.userInfo?.exams) || [];
  const primaryExam = currentUserExams.find((exam) => exam.primary);
  const currentUser = useSelector((state: RootState) => state.auth.userInfo);
  const [showSecondaryScreen, setShowSecondaryScreen] = useState(false);
  const [secondaryScreenType, setSecondaryScreenType] =
    useState<SecondaryScreen | null>(null);
  const [transitionStarted, setTransitionStarted] = useState(false);
  const [isSwitchingExam, setIsSwitchingExam] = useState(false);
  const [isWatched, setIsWatched] = useState(false);
  const [isNoContent, setIsNoContent] = useState(false);
  const [showAppPromo, setShowAppPromo] = useState(false);
  const [justShowedTip, setJustShowedTip] = useState(false);
  const [showTopicPriorities, setShowTopicPriorities] = useState(false);
  const [isExtendingSession, setIsExtendingSession] = useState(false);
  const [screenSaverDisplay, setScreenSaverDisplay] = useState('none');
  const [assentsToAccept, setAssentsToAccept] = useState<
    { type: AssentType; version: string }[]
  >([]);
  const [themeTransitionValue, setThemeTransitionValue] = useState(
    currentUser?.darkMode ? 1 : 0
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { updateTheme } = useUpdateTheme();

  const priorityDisplayRef = useRef<HTMLDivElement>(null);

  // Feature Tour
  const [showHomeTour, setShowHomeTour] = useState(false);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [isTourRunning, setIsTourRunning] = useState(false);
  const tourStep1Ref = useRef<HTMLDivElement>(null);
  const stepSelectors = [
    '.session',
    '.button--view-priorities',
    '.priority-chart_details',
    '.start',
  ];

  const {
    isLoadingStudySessionAndContent,
    isLoadedStudySessionAndContent,
    setIsLoadingStudySessionAndContent,
    setIsLoadedStudySessionAndContent,
    refreshStudySessionAndContent,
  } = useRefreshStudySessionAndContent();
  const windowWidth = useWindowWidth();

  const [activeTopicsChanged, setActiveTopicsChanged] = useState(false);
  const flashLoaderRef = useRef<HTMLDivElement>(null);

  const { isIdle } = useIdle(
    120000,
    isLoadedStudySessionAndContent && !showSecondaryScreen
  );

  useHotkeys('t', () => updateTheme(!currentUser?.darkMode), {
    keyup: true,
  });
  useHotkeys('d', () => updateDemoMode(!currentUser?.demoMode), {
    keyup: true,
  });
  useHotkeys('space', () => initiateTransitionToStudy(), { keyup: true });

  useEffect(() => {
    // Users shouldn't be able to 'undo' flashcards from a previous session
    dispatch(clearPastFlashcards());

    // Clear todaySession if it's not for today
    // TODO: Factor in timezone / offset (?)
    if (todaySession && todaySession.date && !isToday(todaySession.date)) {
      dispatch(clearTodaySession());
      dispatch(clearBlocks());
    }

    determineAssentStatuses();
    // loadStreak();

    if (currentUser?.isOnboarded === false) {
      navigate('/onboarding');
    } else {
      // Loads today's study session and content (flashcards, blocks, vignettes)
      refreshStudySessionAndContent();
    }

    const params = new URLSearchParams(window.location.search);
    if (params.get('delete-request') === 'true') {
      navigate('/account');
    }
  }, []);

  // Show exam confirmation screen
  useEffect(() => {
    if (primaryExam?.examDate) {
      const isPast = moment(primaryExam?.examDate).isBefore(moment(), 'day');
      if (
        isPast &&
        !showSecondaryScreen &&
        !primaryExam.ignoredExamDate &&
        !primaryExam.hasCompletedRealExam
      ) {
        setShowSecondaryScreen(true);
        setSecondaryScreenType(SecondaryScreen.ExamCompletion);
      }
    }
  }, [primaryExam, showSecondaryScreen]);

  useEffect(() => {
    if (isWatched) {
      handleWatchedVideo();
    }
  }, [isWatched]);

  useEffect(() => {
    if (isIdle) {
      setScreenSaverDisplay('block');
    } else {
      setTimeout(() => {
        setScreenSaverDisplay('none');
      }, 1000);
    }
  }, [isIdle]);

  useEffect(() => {
    let value = themeTransitionValue;
    let interval: NodeJS.Timeout;
    if (!isLoadedStudySessionAndContent) return;
    if (currentUser?.darkMode) {
      value = 0;
      interval = setInterval(() => {
        value += 0.01;
        if (value >= 1) {
          value = 1;
          clearInterval(interval);
        }
        setThemeTransitionValue(value);
      }, 1);
    } else {
      value = 1;
      interval = setInterval(() => {
        value -= 0.01;
        if (value <= 0) {
          value = 0;
          clearInterval(interval);
        }
        setThemeTransitionValue(value);
      }, 1);
    }

    return () => clearInterval(interval);
  }, [currentUser?.darkMode]);

  useEffect(() => {
    setIsNoContent(false); // Reset isNoContent
    if (todaySession && todaySession.id && primaryExam) {
      if (todaySession.isComplete) return;
      console.log('Checking if No Content Available for Today');
      if (primaryExam.isUsingFlashcards && !primaryExam.isUsingQbank) {
        setIsNoContent(todaySession.flashcardCount === 0);
      }
      if (primaryExam.isUsingQbank && !primaryExam.isUsingFlashcards) {
        setIsNoContent(todaySession.vignetteCount === 0);
      }
      if (primaryExam.isUsingFlashcards && primaryExam.isUsingQbank) {
        setIsNoContent(
          todaySession.flashcardCount === 0 && todaySession.vignetteCount === 0
        );
      }
    }
  }, [todaySession, primaryExam]);

  // Handle Onboarding Tips
  useEffect(() => {
    let homeTourStartDate = moment.utc('2024-10-22');
    homeTourStartDate = homeTourStartDate.add(3, 'hours');

    // Home Tour
    if (currentUser?.createdAt) {
      const userCreatedAt = moment.utc(currentUser.createdAt);
      if (userCreatedAt.isAfter(homeTourStartDate)) {
        // Prevent showing tour to existing users
        if (currentUser?.onboardingTips && windowWidth > 900) {
          if (!currentUser.onboardingTips['home-tour']) {
            setTimeout(() => {
              setShowHomeTour(true);
              handleStartTour();
            }, 1750);
          }
        }
      }
    }

    // App Promo
    if (
      currentUser &&
      !currentUser?.onboardingTips['app-promo'] &&
      currentUser.onboardingTips['home-tour'] &&
      currentUser.hasWatchedWelcomeVideo &&
      !(currentUser?.hasIOSApp || currentUser?.hasAndroidApp) &&
      !justShowedTip
    ) {
      setShowAppPromo(true);
    }
  }, [
    currentUser?.createdAt,
    currentUser?.onboardingTips,
    windowWidth,
    justShowedTip,
  ]);

  const isToday = (dateString: string) => {
    const eventDate = new Date(dateString);
    const today = new Date();

    return (
      eventDate.getUTCFullYear() === today.getUTCFullYear() &&
      eventDate.getUTCMonth() === today.getUTCMonth() &&
      eventDate.getUTCDate() === today.getUTCDate()
    );
  };

  const determineAssentStatuses = async () => {
    if (!currentUser) return;
    const latestAssents = await getLatestAssents(currentUser.id);

    if (latestAssents) {
      const assentsToAccept = getAssentsToAccept(latestAssents);
      if (assentsToAccept.length > 0) {
        console.log(
          'User needs to accept the following policies:',
          assentsToAccept
        );
        setAssentsToAccept(assentsToAccept);
      } else {
        console.log('User has accepted all the latest policies.');
      }
    } else {
      console.error('Failed to fetch latest assents.');
    }
  };

  const handleWatchedVideo = async () => {
    const updateUser = await updateCurrentUserInfo({
      hasWatchedWelcomeVideo: true,
    });
    if (updateUser) {
      dispatch(setUserInfo(updateUser));
    }
  };

  const handleSeenAppPromo = async () => {
    setShowAppPromo(false);
    setJustShowedTip(true);
    if (!currentUser) return;
    const updateUser = await updateCurrentUserInfo({
      onboardingTips: {
        ...currentUser?.onboardingTips,
        'app-promo': true,
      },
    });
    if (updateUser) {
      dispatch(setUserInfo(updateUser));
    }
  };

  const initiateTransitionToStudy = () => {
    if (!isLoadedStudySessionAndContent) return;
    const examSelector = document.querySelector(
      '.exam-selector'
    ) as HTMLElement;
    if (examSelector) {
      examSelector.style.display = 'none';
    }
    posthog?.capture('study_session_start');
    setTransitionStarted(true);
    setTimeout(() => {
      navigate('/study');
    }, 1000);
  };

  const toggleSecondaryScreen = () => {
    if (showSecondaryScreen) {
      setShowSecondaryScreen(false);
    } else {
      setShowSecondaryScreen(true);
    }
  };

  const updateDemoMode = async (demoMode: boolean) => {
    if (currentUser?.role !== 'admin') return;
    const updatedUser = await updateCurrentUserInfo({ demoMode });
    if (updatedUser) {
      dispatch(setUserInfo(updatedUser));
    }
    enqueueSnackbar(demoMode ? 'Demo Mode Enabled' : 'Demo Mode Disabled', {
      autoHideDuration: 3000,
    });
  };

  const closeSecondaryScreen = async () => {
    setShowSecondaryScreen(false);
    setSecondaryScreenType(null);
  };

  const handleSaveTopicUpdates = async (
    topicFilters: TopicTreeNode[],
    regenerate: boolean
  ) => {
    closeSecondaryScreen();
    if (activeTopicsChanged) {
      setIsLoadingStudySessionAndContent(true);
      setIsLoadedStudySessionAndContent(false);
      const flatTopics = flattenTopicTree(topicFilters);
      await updateTopicTree(flatTopics, regenerate);
      await refreshStudySessionAndContent();
      setIsLoadingStudySessionAndContent(false);
      setIsLoadedStudySessionAndContent(true);
      setActiveTopicsChanged(false);
    }
  };

  const flattenTopicTree = (topicFilters: TopicTreeNode[]) => {
    // @ts-expect-error ignore
    let flatTopics = [];
    topicFilters.forEach((topic) => {
      const topicObj = {
        id: topic.id,
        name: topic.name,
        path: topic.path,
        level: topic.level,
        active: topic.active,
      };
      flatTopics.push(topicObj);
      if (topic.children) {
        // @ts-expect-error ignore
        flatTopics = flatTopics.concat(flattenTopicTree(topic.children));
      }
    });
    // @ts-expect-error ignore
    return flatTopics;
  };

  const handleDateChangeOrConfirmation = async (
    examDate: Moment | null,
    ignored: boolean
  ) => {
    if (!currentUser || !primaryExam) return;

    // Refresh study session and content
    setIsLoadingStudySessionAndContent(true);
    setIsLoadedStudySessionAndContent(false);

    const response = await addOrUpdateUserExam(
      currentUser.id,
      primaryExam.id,
      examDate ? examDate.toISOString() : null,
      primaryExam.selectedFlashcardCount,
      primaryExam.selectedVignetteCount,
      primaryExam.isUsingFlashcards,
      primaryExam.isUsingQbank,
      false, // Has Not Completed Real Exam (In No Path)
      primaryExam.realExamScore || null,
      ignored,
      true
    );
    const updatedUser = { ...currentUser };
    updatedUser.exams = response;
    dispatch(setUserInfo(updatedUser));

    if (!ignored) {
      await refreshStudySessionAndContent();
      enqueueSnackbar('Exam updated.', {
        autoHideDuration: 3000,
        style: { width: 'fit-content' },
      });
    }

    closeSecondaryScreen();
    setIsLoadingStudySessionAndContent(false);
    setIsLoadedStudySessionAndContent(true);

    if (document.activeElement && document.activeElement !== document.body) {
      (document.activeElement as HTMLElement).blur();
    }
  };

  const handleUnsnoozeSession = async () => {
    setIsLoadingStudySessionAndContent(true);
    setIsLoadedStudySessionAndContent(false);
    await new Promise((resolve) => setTimeout(resolve, 1000));
    const updatedSession = { ...todaySession, isSnoozed: false };
    const response = await updateStudySession(todaySession.id, updatedSession);
    dispatch(setTodaySession(response));
    await refreshStudySessionAndContent();
    setIsLoadingStudySessionAndContent(false);
    setIsLoadedStudySessionAndContent(true);
  };

  const renderSearchBar = () => {
    return (
      <div className="search-bar">
        <Search isArticleBrowser={false} />
      </div>
    );
  };

  const renderHomeLoader = () => {
    return (
      <div
        className={`home-loader${isLoadedStudySessionAndContent ? ' is-loaded' : ''}${isLoadingStudySessionAndContent ? ' start-loading' : ''}`}
        style={showSecondaryScreen ? { opacity: 0 } : {}}
      >
        <div>
          <h2 style={{ marginBottom: 0 }}>
            Hi, {currentUser?.name ? currentUser?.name : 'there'}.
          </h2>
          <div style={{ display: 'flex', alignItems: 'center', gap: '.5rem' }}>
            <CircularProgress
              sx={{
                color: 'var(--type-body-inverse)',
                height: '1rem !important',
                width: '1rem !important',
              }}
            />
            <p>Optimizing your session...</p>
          </div>
        </div>
      </div>
    );
  };

  // Tour Functions
  const handleStartTour = () => {
    setCurrentStepIndex(0);
    setIsTourRunning(true);
  };

  const handleTourEnd = () => {
    setIsTourRunning(false);
    setCurrentStepIndex(0);
  };

  const handleStepChange = (newIndex: number) => {
    setCurrentStepIndex(newIndex);
  };

  const renderAppPromo = (show: boolean) => {
    if (!show) return null;
    return (
      <div className="app-promo">
        <div className="app-promo_header">
          <h2>Download the Ora App</h2>
          <div style={{ alignSelf: 'baseline' }}>
            <button
              className="button button--close"
              onClick={handleSeenAppPromo}
            >
              <X />
            </button>
          </div>
        </div>
        <div className="app-promo_body">
          <div>
            <img src={AppPromo} alt="Download Ora Mobile Apps" />
          </div>
          <div className="qr">
            <img src={QRCode} alt="Ora QR Code" />
          </div>
        </div>
        {/* <div className="app-promo_footer">
          <a
            className="button button--glass"
            href="https://apps.apple.com/us/app/ora-ai-guided-step-prep/id6484319369"
            rel="noreferrer"
            target="_blank"
          >
            <img src={AppleIcon} alt="Download on the App Store" />
          </a>
          <a
            className="button button--glass"
            href="https://play.google.com/store/apps/details?id=com.oraai.app"
            rel="noreferrer"
            target="_blank"
          >
            <div className="google-wrapper">
              <img src={GoogleIcon} alt="Get it on Google Play" />
            </div>
          </a>
        </div> */}
      </div>
    );
  };

  const renderStartButton = () => {
    if (
      (!todaySession.isComplete &&
        !isNoContent &&
        !todaySession.isSnoozed &&
        !showTopicPriorities) ||
      isExtendingSession
    ) {
      return (
        <Start
          startTransition={initiateTransitionToStudy}
          isStarted={todaySession.isStarted}
          isExtendingSession={isExtendingSession}
          setIsExtendingSession={setIsExtendingSession}
          setShowTopicPriorities={setShowTopicPriorities}
          type={StartType.Home}
          isSwitchingExam={isSwitchingExam}
          isLoadedStudySessionAndContent={isLoadedStudySessionAndContent}
          isLoadingStudySessionAndContent={isLoadingStudySessionAndContent}
        />
      );
    } else {
      return null;
    }
  };

  const renderExamCompletion = () => {
    if (todaySession.isComplete || isNoContent || todaySession.isSnoozed) {
      return (
        <div className="is-done">
          {!isExtendingSession && !showTopicPriorities && (
            <>
              <Check />
              <h1>
                {todaySession.isSnoozed ? (
                  <>
                    <span>Your workload has been rebalanced.</span>
                    <br />
                    <span>Enjoy your day!</span>
                  </>
                ) : (
                  "You're Done for Today"
                )}
              </h1>
            </>
          )}
          {!isNoContent &&
            !todaySession.isSnoozed &&
            !isExtendingSession &&
            !showTopicPriorities && (
              <div className="is-done_buttons">
                <button
                  role="link"
                  className="button button--link button--link--glass"
                  onClick={() => {
                    navigate('/study');
                    hapticsImpactLight();
                  }}
                >
                  Today's Stats
                  <ArrowRight />
                </button>
                <button
                  role="link"
                  className="button button--link button--link--glass"
                  onClick={() => {
                    setIsExtendingSession(true);
                    hapticsImpactLight();
                  }}
                >
                  Modify Session
                  <ArrowRight />
                </button>
              </div>
            )}
          {!isNoContent && todaySession.isSnoozed && !showTopicPriorities && (
            <button
              className="button button--link button--unsnooze button--link--glass"
              onClick={() => {
                handleUnsnoozeSession();
                hapticsImpactLight();
              }}
            >
              <AlarmClock />
              Unsnooze Today's Session
            </button>
          )}
          {isNoContent && (
            <p style={{ textAlign: 'center', padding: '0 2rem' }}>
              You don't have any due content today. Enjoy your day off!
            </p>
          )}
        </div>
      );
    } else {
      return null;
    }
  };

  const renderPriorityDisplay = () => {
    return (
      <div
        ref={priorityDisplayRef}
        style={{
          maxWidth: '60rem',
        }}
      ></div>
    );
  };

  // const restartTour = () => {
  //   setShowHomeTour(true);
  //   handleStartTour();
  // };

  const screensaverElement = (
    <div
      className={`screensaver ${isIdle ? 'in' : 'out'}`}
      style={{ display: screenSaverDisplay }}
    >
      <ThreeOrb isScreensaver={true} />
    </div>
  );

  return (
    <div
      className="index-container"
      style={{ background: interpolateGradient(themeTransitionValue) }}
    >
      {renderHomeLoader()}
      <div
        className={`home ${transitionStarted ? 'transitioning' : ''} ${isLoadedStudySessionAndContent ? 'is-loaded' : ''}${themeTransitionValue < 1 && themeTransitionValue > 0 ? ' theme-transition' : ''}`}
      >
        {!showSecondaryScreen && <NavBar />}
        <div className="home_body" ref={tourStep1Ref}>
          <Header
            type={HeaderType.home}
            isDiagnostic={false}
            setIsSwitchingExam={setIsSwitchingExam}
            secondaryScreenType={secondaryScreenType}
            secondaryScreenIsOpen={showSecondaryScreen}
            closeSecondaryScreen={closeSecondaryScreen}
            openTopicFilters={() => {
              toggleSecondaryScreen();
              setSecondaryScreenType(SecondaryScreen.TopicFilters);
            }}
            handleSaveTopicUpdates={handleSaveTopicUpdates}
            activeTopicsChanged={activeTopicsChanged}
            setIsLoadingStudySessionAndContent={
              setIsLoadingStudySessionAndContent
            }
            setIsLoadedStudySessionAndContent={
              setIsLoadedStudySessionAndContent
            }
            refreshStudySessionAndContent={refreshStudySessionAndContent}
          />
          {!showSecondaryScreen && (
            <>
              <div className="home_body_content">
                {renderStartButton()}
                {renderExamCompletion()}
                {renderPriorityDisplay()}
              </div>
              {renderSearchBar()}
              {currentUser && !currentUser.hasWatchedWelcomeVideo && (
                <WelcomeVideo
                  isReminder={true}
                  setIsWatched={setIsWatched}
                  setJustShowedTip={setJustShowedTip}
                />
              )}
              <PriorityDisplay
                containerRef={priorityDisplayRef}
                showTopicPriorities={showTopicPriorities}
                setShowTopicPriorities={setShowTopicPriorities}
                setIsLoadingStudySessionAndContent={
                  setIsLoadingStudySessionAndContent
                }
                setIsLoadedStudySessionAndContent={
                  setIsLoadedStudySessionAndContent
                }
                refreshStudySessionAndContent={refreshStudySessionAndContent}
              />
              {renderAppPromo(showAppPromo)}
            </>
          )}
          {showSecondaryScreen && (
            <div className="secondary-screen">
              {secondaryScreenType === SecondaryScreen.TopicFilters && (
                <TopicFilters setActiveTopicsChanged={setActiveTopicsChanged} />
              )}
              {secondaryScreenType === SecondaryScreen.ExamCompletion && (
                <ExamCompletion
                  closeSecondaryScreen={closeSecondaryScreen}
                  handleDateChangeOrConfirmation={
                    handleDateChangeOrConfirmation
                  }
                />
              )}
            </div>
          )}
        </div>
        {!showSecondaryScreen && <AppBar />}
      </div>
      <div id="side-drawer-root"></div>
      <div className="flash-loader" ref={flashLoaderRef}></div>
      {showHomeTour && (
        <HomeFeatureTour
          stepTargets={[...stepSelectors]}
          stepFunctions={[() => {}, () => {}, () => {}]}
          run={isTourRunning}
          stepIndex={currentStepIndex}
          onStepChange={handleStepChange}
          onTourEnd={handleTourEnd}
          setShowTopicPriorities={setShowTopicPriorities}
        />
      )}
      <AssentsModal
        currentUser={currentUser}
        assentsToAccept={assentsToAccept}
        setAssentsToAccept={setAssentsToAccept}
      />
      {createPortal(screensaverElement, document.body)}
    </div>
  );
};

export default Home;
