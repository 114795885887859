import React from 'react';
import { Menu } from 'react-feather';
import OraLogo from '../../Global/OraLogo';
import MSMobileMenu from '../MSMobileMenu';
import { posthog } from 'posthog-js';

interface MSHeaderProps {}

const MSHeader: React.FC<MSHeaderProps> = () => {
  return (
    <>
      <header className="ms-header">
        <div className="logo-wrapper">
          <button
            className="menu-toggle"
            onClick={() => {
              const mobileMenu = document.querySelector(
                '.ms-mobile-menu_drawer'
              );
              if (mobileMenu) {
                mobileMenu.classList.add('is-open');
              }
            }}
          >
            <Menu />
          </button>
          <OraLogo type="link" url={'https://www.oraai.com/'} />
        </div>
        <div className="menu">
          <a href="https://oraai.com/content">How It Works</a>
          <a href="/library">Library</a>
          <a href="https://oraai.com/team">Team</a>
          <button
            onClick={() => {
              window.Intercom('showNewMessage');
            }}
          >
            Contact
          </button>
        </div>
        <div className="links">
          <a
            className="button button--link"
            href="https://app.oraai.com/"
            type="button"
            onClick={() => {
              posthog?.capture('ms_sign_in');
            }}
          >
            Sign In
          </a>
          <a
            className="button button--glass"
            href="https://app.oraai.com/auth?signup=true"
            type="button"
            style={{ borderRadius: '100rem' }}
            onClick={() => {
              posthog?.capture('ms_sign_up', { isHero: false });
            }}
          >
            Create Account
          </a>
        </div>
      </header>
      <MSMobileMenu />
    </>
  );
};

export default MSHeader;
