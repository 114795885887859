import React from 'react';
import OraLogo from '../../Global/OraLogo';
import AppStoreBadge from '../../../assets/images/app-store-badge.svg';

const MSFooter: React.FC = () => {
  return (
    <footer className="ms-footer">
      <div className="footer_main">
        <div className="footer_main_left">
          <OraLogo type="link" url={'https://www.oraai.com/'} />
          <div>
            <p>The new gold standard QBank for Step prep</p>
          </div>
          <div className="app-badges">
            <a
              href="https://apps.apple.com/us/app/ora-ai-guided-step-prep/id6484319369"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="download-badge"
                src={AppStoreBadge}
                alt="Download on the App Store"
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.oraai.app"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="download-badge download-badge--play"
                src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                alt="Download on the Play Store"
              />
            </a>
          </div>
        </div>
        <div className="footer_main_right">
          <div className="section-label">About</div>
          <div className="footer_main_right_links">
            <div>
              <a href="https://www.oraai.com/terms">Terms of Use</a>
            </div>
            <div>
              <a href="https://www.oraai.com/privacy">Privacy Policy</a>
            </div>
          </div>
        </div>
      </div>
      <div className="footer_sub">
        Synaptiq Learning Inc. | &copy; 2024 | All Rights Reserved
      </div>
    </footer>
  );
};

export default MSFooter;
