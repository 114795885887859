import React from 'react';
import { CircularProgress } from '@mui/material';

interface JumpToMenuProps {
  headings: { title: string; id: string }[];
  onClick: (id: string) => void;
  loading: boolean;
}

const JumpToMenu: React.FC<JumpToMenuProps> = ({
  headings,
  onClick,
  loading,
}) => {
  return (
    <nav className="jumpto-menu">
      <div className="top">
        <h3>Jump To</h3>
      </div>
      {loading ? (
        <CircularProgress />
      ) : (
        <ul>
          {headings.map((heading, index) => (
            <li key={index} data-scrollspy-id={'spy-' + index}>
              <button onClick={() => onClick(heading.id)}>
                {heading.title}
              </button>
            </li>
          ))}
        </ul>
      )}
    </nav>
  );
};

export default JumpToMenu;
