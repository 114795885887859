import React from 'react';
import { Ora } from '../../../assets/svgs/Ora';
import { hapticsImpactLight } from '../../../utils/haptics';

interface OraLogoProps {
  type?: string;
  url?: string;
  clickFunction?: () => void;
}

const OraLogo: React.FC<OraLogoProps> = ({ type, url, clickFunction }) => {
  return (
    <div className="logo">
      {type === 'link' && (
        <a href={url} style={{ color: 'var(--type-body-inverse)' }}>
          <Ora />
        </a>
      )}
      {type === 'button' && (
        <button
          onClick={() => {
            hapticsImpactLight();
            if (clickFunction) {
              clickFunction();
            }
          }}
        >
          <Ora />
        </button>
      )}
      {!type && <Ora />}
    </div>
  );
};

export default OraLogo;
