export const LinkConnector = () => {
  return (
    <svg
      width="212"
      height="58"
      viewBox="0 0 212 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M106.5 3.93652C107.359 3.71387 108 2.93164 108 2C108 0.895508 107.102 0 106 0C105.484 0 105.008 0.198242 104.648 0.523438C104.484 0.671875 104.352 0.84668 104.242 1.04102C104.086 1.32617 104 1.65332 104 2C104 2.93164 104.641 3.71387 105.5 3.93652V14C105.5 20.3516 100.352 25.5 94 25.5H14C7.09375 25.5 1.5 31.0967 1.5 38V54.0635C0.640625 54.2861 0 55.0684 0 56C0 56.5566 0.226562 57.0596 0.59375 57.4219C0.953125 57.7793 1.45312 58 2 58C2.60156 58 3.14062 57.7324 3.50781 57.3105C3.58594 57.2246 3.65625 57.1318 3.71094 57.0332C3.89062 56.7314 4 56.3779 4 56C4 55.0684 3.35938 54.2861 2.5 54.0635V38C2.5 31.6484 7.64844 26.5 14 26.5H94C99.1641 26.5 103.594 23.3711 105.5 18.9072V54.0635C104.641 54.2861 104 55.0684 104 56C104 57.1045 104.898 58 106 58C107.102 58 108 57.1045 108 56C108 55.3672 107.703 54.8037 107.25 54.4375C107.031 54.2646 106.773 54.1348 106.5 54.0635V18.9072C108.406 23.3711 112.836 26.5 118 26.5H198C204.352 26.5 209.5 31.6484 209.5 38V54.0635C208.641 54.2861 208 55.0684 208 56C208 57.1045 208.898 58 210 58C211.102 58 212 57.1045 212 56C212 55.0684 211.359 54.2861 210.5 54.0635V38C210.5 31.0967 204.906 25.5 198 25.5H118C111.648 25.5 106.5 20.3516 106.5 14V3.93652Z"
        fill="currentColor"
      />
    </svg>
  );
};
