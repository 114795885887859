import React, { useEffect, useState } from 'react';
import { hapticsImpactLight } from '../../../utils/haptics';
import { BarChart2, Book, Home, MoreHorizontal } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import SettingsMenu from '../SettingsMenu';
import { TabTypes } from '../../../types/Global';

interface AppBarProps {}

const AppBar: React.FC<AppBarProps> = () => {
  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState<TabTypes>(TabTypes.Home);

  const navigate = useNavigate();

  useEffect(() => {
    if (window.location.pathname === '/') {
      setActiveTab(TabTypes.Home);
    } else if (window.location.pathname === '/performance') {
      setActiveTab(TabTypes.Stats);
    } else if (window.location.pathname === '/library') {
      setActiveTab(TabTypes.Library);
    } else {
      setActiveTab(TabTypes.null);
    }
  }, [navigate]);

  return (
    <>
      <nav className="app-bar">
        <div className="app-bar_inner">
          <button
            className={`app-bar_inner_button button button--home ${activeTab === TabTypes.Home ? 'active' : ''}`}
            onClick={() => {
              hapticsImpactLight();
              navigate('/');
            }}
          >
            <Home />
            <span>Home</span>
          </button>
          <button
            className={`app-bar_inner_button button button--performance ${activeTab === TabTypes.Stats ? 'active' : ''}`}
            onClick={() => {
              hapticsImpactLight();
              navigate('/performance');
            }}
          >
            <BarChart2 />
            <span>Stats</span>
          </button>
          <button
            id="library-tab"
            role="link"
            className={`app-bar_inner_button button button--library ${activeTab === TabTypes.Library ? 'active' : ''}`}
            onClick={() => {
              hapticsImpactLight();
              navigate('/library');
            }}
          >
            <Book />
            <span>Library</span>
          </button>
          <button
            className="app-bar_inner_button button button--mobile-menu"
            onClick={() => {
              hapticsImpactLight();
              if (setMobileMenuIsOpen) {
                setMobileMenuIsOpen(true);
              }
            }}
          >
            <MoreHorizontal />
            <span>More</span>
          </button>
        </div>
      </nav>
      <SettingsMenu
        mobileMenuIsOpen={mobileMenuIsOpen}
        setMobileMenuIsOpen={setMobileMenuIsOpen}
      />
    </>
  );
};

export default AppBar;
