export enum AssentType {
  tos = 'tos',
  privacyPolicy = 'privacyPolicy',
}

export const AssentTypeLabels = {
  [AssentType.tos]: 'Terms of Use',
  [AssentType.privacyPolicy]: 'Privacy Policy',
};

export const AssentTypeLinks = {
  [AssentType.tos]: 'http://oraai.com/terms',
  [AssentType.privacyPolicy]: 'http://oraai.com/privacy',
};

export interface Assent {
  id?: string;
  type: AssentType;
  dateAccepted: string;
  location: string;
  version: string;
}
