import { AlertTriangle, ArrowLeft } from 'react-feather';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { BlockType } from '../../../types/Study';
import CustomModal from '../CustomModal';

interface RegenerateModalProps {
  showRegenModal: boolean;
  setShowRegenModal: (show: boolean) => void;
  submitChanges: (regenerate: boolean) => void;
  type: 'modify-today' | 'exam-settings';
}

const RegenerateModal: React.FC<RegenerateModalProps> = ({
  showRegenModal,
  setShowRegenModal,
  submitChanges,
  type,
}) => {
  const activeBlockId = useSelector(
    (state: RootState) => state.todaySession.activeBlockId
  );
  const activeBlock = useSelector(
    (state: RootState) => state.blocks.find((b) => b.id === activeBlockId)!
  );

  return (
    <CustomModal
      open={showRegenModal}
      onClose={() => setShowRegenModal(false)}
      name={'regenerate'}
      glass={true}
      disableBackdropClick={true}
    >
      <div className="modal_header">
        <h2>Regenerate Today's Session?</h2>
      </div>
      <div className="modal_content">
        <p>
          {type === 'modify-today'
            ? "You've already started today's session. To apply these changes, you must regenerate today's session."
            : "You've already started today's session. You can regenerate today's session with these changes, or apply them tomorrow."}
        </p>
        {activeBlock?.isStarted && activeBlock.type === BlockType.timed && (
          <p>
            <AlertTriangle
              style={{
                display: 'inline',
                height: '1rem',
                transform: 'translateY(3px)',
              }}
            />
            WARNING: You are in the middle of a timed block. Regenerating will
            result in lost progress. To save your answers, submit the block
            prior to regenerating.
          </p>
        )}
      </div>
      <div className="modal_actions">
        <button
          className="button button--glass button--back"
          onClick={() => setShowRegenModal(false)}
        >
          <ArrowLeft />
          <span>Back</span>
        </button>
        <div>
          {type !== 'modify-today' && (
            <button
              className="button button--glass--link"
              onClick={() => {
                submitChanges(false);
                setShowRegenModal(false);
              }}
            >
              Apply Tomorrow
            </button>
          )}
          <button
            className="button button--glass"
            onClick={() => {
              submitChanges(true);
              setShowRegenModal(false);
            }}
          >
            Regenerate
          </button>
        </div>
      </div>
    </CustomModal>
  );
};

export default RegenerateModal;
