import React, { useEffect, useRef } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip as ChartTooltip,
  Legend,
  Filler,
} from 'chart.js';
import moment from 'moment';
import { hapticsImpactLight } from '../../../utils/haptics';
import { ArrowRight } from 'react-feather';
import { debounce } from 'lodash';
import { Capacitor } from '@capacitor/core';
import Skeleton from '@mui/material/Skeleton';
import PlaceholderCurve from '../../../assets/images/placeholderCurve.png';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  ChartTooltip,
  Legend,
  Filler
);

interface DataPoint {
  date: string;
  correctCount: number;
  incorrectCount: number;
  omittedCount: number;
  score: number;
  isDiagnostic: boolean;
}

interface QBankPerformanceProps {
  data: DataPoint[] | null;
  showPreviousBlocks: () => void;
}

// Uncomment for Demo Data
// const fakeData: DataPoint[] = [
//   {
//     date: moment().subtract(4, 'days').format('YYYY-MM-DD'),
//     correctCount: 8,
//     incorrectCount: 2,
//     omittedCount: 0,
//     score: 70,
//     isDiagnostic: false,
//   },
//   {
//     date: moment().subtract(3, 'days').format('YYYY-MM-DD'),
//     correctCount: 9,
//     incorrectCount: 1,
//     omittedCount: 0,
//     score: 75,
//     isDiagnostic: false,
//   },
//   {
//     date: moment().subtract(2, 'days').format('YYYY-MM-DD'),
//     correctCount: 8,
//     incorrectCount: 1,
//     omittedCount: 1,
//     score: 85,
//     isDiagnostic: false,
//   },
//   {
//     date: moment().subtract(1, 'days').format('YYYY-MM-DD'),
//     correctCount: 8,
//     incorrectCount: 2,
//     omittedCount: 0,
//     score: 80,
//     isDiagnostic: false,
//   },
//   {
//     date: moment().format('YYYY-MM-DD'),
//     correctCount: 9,
//     incorrectCount: 0,
//     omittedCount: 1,
//     score: 90,
//     isDiagnostic: false,
//   },
// ];

const QBankPerformance: React.FC<QBankPerformanceProps> = ({
  data,
  showPreviousBlocks,
}) => {
  // data = fakeData; Uncomment for Demo Data

  const chartRef = useRef<ChartJS<'line', number[], string> | null>(null);

  const sortedData = data ? [...data] : [];
  sortedData.sort((a, b) => {
    return moment(a.date).valueOf() - moment(b.date).valueOf();
  });

  let labels: string[] = [];
  let scores: number[] = [];
  let pointRadius: number[] = [];

  if (sortedData.length === 1) {
    const singleDate = moment(sortedData[0].date).format('MMMM D, YYYY');
    const label = sortedData[0].isDiagnostic ? 'Diagnostic' : singleDate;
    labels = ['', label, ''];
    scores = [sortedData[0].score, sortedData[0].score, sortedData[0].score];
    pointRadius = [0, 3, 0]; // Hide fake points, show actual point
  } else {
    labels = sortedData.map((dp) => {
      if (dp.isDiagnostic) {
        return 'Diagnostic';
      } else {
        return moment(dp.date).format('MMMM D, YYYY');
      }
    });
    scores = sortedData.map((dp) => dp.score);
    pointRadius = new Array(scores.length).fill(3); // Show all points
  }

  const diagnosticIndex = labels.indexOf('Diagnostic');
  if (diagnosticIndex !== -1 && sortedData.length > 1) {
    labels.unshift(labels.splice(diagnosticIndex, 1)[0]);
    scores.unshift(scores.splice(diagnosticIndex, 1)[0]);
    pointRadius.unshift(pointRadius.splice(diagnosticIndex, 1)[0]);
  }

  const lineData = {
    labels: labels,
    datasets: [
      {
        label: 'Score',
        data: scores,
        borderColor: '#FEFEFE',
        borderWidth: 1,
        pointRadius: pointRadius,
        backgroundColor: 'transparent', // This will be overridden in useEffect
        fill: true,
        tension: 0.4,
        pointBackgroundColor: '#FEFEFE',
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          // @ts-expect-error - TS doesn't like the context having type any
          label: function (context) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.raw !== null) {
              label += context.raw + '%';
            }
            return label;
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          color: 'rgba(255,255,255,.3)',
        },
        ticks: {
          display: Capacitor.isNativePlatform() ? false : true,
          color: '#FEFEFE',
          font: {
            family: 'Poppins',
          },
        },
        border: {
          color: '#FEFEFE',
        },
      },
      y: {
        beginAtZero: true,
        min: 0,
        max: 100,
        grid: {
          display: false,
          color: 'rgba(255,255,255,.3)',
        },
        ticks: {
          stepSize: 10,
          color: '#FEFEFE',
          font: {
            family: 'Poppins',
          },
          // @ts-expect-error - TS doesn't like the context having type any
          callback: function (value) {
            return value + '%';
          },
        },
        border: {
          color: '#FEFEFE',
        },
      },
    },
    animation: {
      onComplete: () => {
        const chart = chartRef.current;
        if (chart) {
          const ctx = chart.ctx;
          const gradient = ctx.createLinearGradient(
            0,
            0,
            0,
            chart.chartArea.bottom
          );
          gradient.addColorStop(0, 'rgba(255, 255, 255, 0.5)');
          gradient.addColorStop(1, 'rgba(255, 255, 255, .02)');
          chart.data.datasets[0].backgroundColor = gradient;
          chart.update();
        }
      },
    },
  };

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.update();
    }
  }, [data]);

  useEffect(() => {
    const handleResize = debounce(() => {
      const chart = chartRef.current;
      if (chart) {
        if (window.innerWidth > 1200) {
          const dashboardPanel = document.querySelector(
            '.dashboard_wrapper_body_panel'
          );
          const chartContainer = document.querySelector(
            '.chart-container'
          ) as HTMLElement | null;
          if (dashboardPanel && chartContainer) {
            chartContainer.style.height = `${dashboardPanel.clientHeight}px`;
            chart.resize();
          }
        }
      }
    }, 300);

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="block block--dashboard qbank-performance">
      <div className="block_body">
        <h2 className="m-t-0">
          <span>QBank</span> Performance
        </h2>
        {!data && (
          <Skeleton
            sx={{
              bgcolor: 'rgba(255,255,255,.05)',
              borderRadius: '1rem',
            }}
            variant="rectangular"
            animation="wave"
            width={'100%'}
            height={'100%'}
          />
        )}
        {data && data.length > 0 && (
          <div className="chart-container">
            <Line data={lineData} options={chartOptions} ref={chartRef} />
          </div>
        )}
        {data && data.length === 0 && (
          <div className="no-data">
            QBank performance will appear here.
            <img src={PlaceholderCurve} alt="" />
          </div>
        )}
        {data && data.length > 0 && (
          <button
            style={{ display: 'flex', marginLeft: 'auto', padding: 0 }}
            className="button button--link--glass m-t-1"
            onClick={() => {
              showPreviousBlocks();
              hapticsImpactLight();
            }}
          >
            Review Previous Blocks
            <ArrowRight />
          </button>
        )}
      </div>
    </div>
  );
};

export default QBankPerformance;
