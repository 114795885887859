import axiosInstance from './axiosCreator';

export const fetchScheduleStats = async () => {
  try {
    const response = await axiosInstance.get('/stats/schedule');
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const fetchStudySessionTopics = async (sessionId: string) => {
  try {
    const response = await axiosInstance.get(
      `/stats/studySessionTopics/${sessionId}`
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const fetchQbankPerformanceStats = async () => {
  try {
    const response = await axiosInstance.get('/stats/qbankPerformance');
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const fetchTopicMasteryStats = async () => {
  try {
    const response = await axiosInstance.get('/stats/topicMastery');
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const fetchSubtopicMasteryStats = async (topicId: string) => {
  try {
    const response = await axiosInstance.get(
      `/stats/subtopicMastery/${topicId}`
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const fetchTopicsForFlashcards = async (flashcardIds: string[]) => {
  try {
    const response = await axiosInstance.post('/stats/flashcardTopics', {
      flashcardIds,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const fetchTopLevelTopics = async () => {
  try {
    const response = await axiosInstance.get('/stats/topLevelTopics');
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const fetchTopicPriorities = async (
  examId: string,
  sessionId: string
) => {
  try {
    const response = await axiosInstance.post('/stats/topicPriorities', {
      examId,
      sessionId,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
