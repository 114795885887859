export enum HeaderType {
  default = 'default',
  home = 'home',
  generic = 'generic',
  study = 'study',
}

export enum SecondaryScreen {
  TopicFilters = 'TopicFilters',
  ExamCompletion = 'ExamCompletion',
}
