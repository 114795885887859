import { VignetteAnswerOption } from '../types/Vignette';
import axiosInstance from './axiosCreator';

interface VignetteUpdateData {
  question: string;
  explanation: string;
  exhibit: string;
  learningObjective: string;
  correctAnswer: string;
  answerOptions: VignetteAnswerOption[];
}

// Submit a vignette
export const submitTutorVignette = async (
  vignetteId: string,
  userAnswer: string,
  correctAnswer: string,
  submitted: boolean,
  isDemo: boolean = false
) => {
  try {
    const response = await axiosInstance.put(
      `/vignettes/${vignetteId}/submit`,
      {
        userAnswer: userAnswer,
        correctAnswer: correctAnswer,
        submitted: submitted,
        isDemo: isDemo,
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// Fetch Linked Flashcards
export const fetchLinkedFlashcards = async (vignetteId: string) => {
  try {
    const response = await axiosInstance.get(
      `/vignettes/${vignetteId}/flashcards`
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// Fetch Vignette Content Completion
export const fetchVignetteCompletion = async (
  userId: string,
  examId: string
) => {
  try {
    const response = await axiosInstance.post(
      `/vignettes/vignetteContentCompletion`,
      {
        userId: userId,
        examId: examId,
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// Update Vignette by ID
export const updateVignetteBE = async (
  id: string,
  data: {
    vignetteYield: number;
    firstSessionOrd: number | null;
    exams: string[];
  }
) => {
  try {
    const response = await axiosInstance.put(`/vignettes/parent/${id}`, data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// Update Vignette Variant by ID
export const updateVignetteVariantBE = async (
  variantId: string,
  data: VignetteUpdateData
) => {
  try {
    const response = await axiosInstance.put(
      `/vignettes/variant/${variantId}`,
      data
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// Update Vignette Global Suspend Status
export const updateVignetteSuspendStatus = async (
  id: string,
  data: { isSuspended: boolean }
) => {
  try {
    const response = await axiosInstance.put(`/vignettes/${id}/suspend`, data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// Set Vignette isQAed
export const setVignetteQAStatus = async (id: string, isQAed: boolean) => {
  try {
    const response = await axiosInstance.put(`/vignettes/${id}/qa`, { isQAed });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
